import { ViewingHistoryItem } from 'scripts/modules/get-user-viewing-history'

export const getVideoPercentageWatched = (slug: string, depUserViewingHistory?: Array<ViewingHistoryItem>): number | null => {
  const storedViewingHistory =
    depUserViewingHistory || JSON.parse(localStorage.getItem('userViewingHistory'));

  if (!storedViewingHistory) {
    return null;
  }

  const found = storedViewingHistory.find(
    (viewingHistoryItem: ViewingHistoryItem) => viewingHistoryItem.slug === slug
  );

  return found ? found.percentageWatched : null;
}
