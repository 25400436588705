import Ad from './ad';
import { isViewportOver } from 'scripts/utils/isViewportOver';
import { isTouchDevice } from 'scripts/utils/isTouchDevice';
import _debounce from 'lodash.debounce';

const ResponsiveAd = class ResponsiveAd extends Ad {
  isViewportOver: (params: number) => boolean;
  isTouchDevice: () => boolean;
  el: HTMLElement;
  type: string;
  mappingSizes: {
    mobile: Array<Array<number>>,
    tablet: Array<Array<number>>,
    desktop: Array<Array<number>>,
  }

  /**
   * Initializes class instance.
   * param {object} options - any custom options passed in
   * @param {array} options.sizes - this will override desktop sizes
   * @overrides
   */
  constructor(options: Defaults) {
    super(options);

    const defaults: Defaults = {
      responsive: true,
      mappingSizes: {
        mobile: [[320, 400], []],
        tablet: [[750, 200], []],
        desktop: [
          [1024, 200],
          [300, 250],
        ],
      },
    };

    // loop through all defaults and set value to any options passed in
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.updateDefaults(this, defaults, options);

    this.onWindowResize = _debounce(this.onWindowResize.bind(this), 200);
    this.isViewportOver = isViewportOver;
    this.isTouchDevice = isTouchDevice;
  }

  /**
   * Builds ad mappings for responsive ads.
   * @returns {ojbect} - googletag mapping
   */
  // returning any here because we don't have an exhaustive type for googletag
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  buildSizeMap(): any {
    // for ad slots, let the custom ad sizes be used for desktop size ads
    if (this.type !== 'adhesion' && Object.keys(this.sizes).length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.mappingSizes.desktop[1] = this.sizes;
    }

    // define a size mapping object
    // see https://support.google.com/dfp_premium/answer/4578089#responsiveDesign
    return (
      window.googletag
        .sizeMapping()
        // for mobile
        .addSize(this.mappingSizes.mobile[0], this.mappingSizes.mobile[1])
        // for tablet
        .addSize(this.mappingSizes.tablet[0], this.mappingSizes.tablet[1])
        // for desktop
        .addSize(this.mappingSizes.desktop[0], this.mappingSizes.desktop[1])
        .build()
    );
  }

  /**
   * Sets slot definition to include size mapping definition.
   * @override - overrides setSlot in Ad class
   */
  // returning any here because we don't have an exhaustive type for googletag
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSlot(): any {
    const map = this.buildSizeMap();

    return this.defineSlot()
      .defineSizeMapping(map)
      .setCollapseEmptyDiv(true)
      .addService(window.googletag.pubads());
  }

  /**
   * Adds rendered class when ad is rendered.
   * @override - overrides onAdRendered in Ad class
   */
  onAdRendered(e: GoogleAdRenderedEvent): void {
    super.onAdRendered(e);

    // if an ad was rendered
    // and doesn't have rendered class
    if (
      this.loaded &&
      !this.isEmpty &&
      !this.el.classList.contains('ad--rendered')
    ) {
      this.el.classList.add('ad--rendered');
    }
  }

  /**
   * On window resize event.
   */
  onWindowResize(): void {
    // if on a desktop and it hasn't been refreshed
    if (this.isViewportOver(1024) && !this.isTouchDevice()) {
      // refresh ad slots
      if (this.slot) {
        this.load();
      }

      // remove event listener for resize
      window.removeEventListener('resize', this.onWindowResize);
    }
  }

  /**
   * Adds event handlers.
   */
  addEvents(): void {
    // add window resize event for responsive ads
    if (!this.isViewportOver(1024)) {
      window.addEventListener('resize', this.onWindowResize);
    }
  }

  /**
   * Renders ad.
   * @override - overrides render in Ad class
   */
  // returning any here because TS just does not want to play nice with class inheritance
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render(): any {
    super.render();
    this.addEvents();
  }
};

export default ResponsiveAd;
