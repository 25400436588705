import React, { useState } from 'react';

import ImageWithFallback from 'components/shared/react-image-with-fallback';
import CaretRight from 'components/shared/caret-right';
import { formatDuration } from 'scripts/utils/formatDuration';

export const ShowRowPreviewFeaturedAssets: React.FC<ShowRowPreviewFeaturedAssetsProps> = (props) => {
  const { slug, featuredAssetsData } = props;
  const [hasBrokenImage, setHasBrokenImage] = useState(false);

  const onBrokenImage = (broken: boolean) => {
    setHasBrokenImage(broken);
  }

  return (
    <div className="show-row-preview-modal__featured-assets">
      <h2 className="show-row-preview-modal__featured-assets__title">
        <a
          className="show-row-preview-modal__featured-assets__title-link"
          href={`/show/${slug}/episodes/`}
        >
          <span className="show-row-preview-modal__featured-assets__title-text">Episodes</span>
          <CaretRight />
        </a>
      </h2>
      <div className="show-row-preview-modal__featured-assets__container">
        {featuredAssetsData.map((asset: Asset) => (
            <div
              className="show-row-preview-modal__featured-asset"
              key={asset.cid}
              data-testid="rtl-featuredAsset"
            >
              <div className="show-row-preview-modal__featured-asset__image-wrapper has-play-icon">
                <a
                  href={`/video/${asset.slug}/`}
                  className="show-row-preview-modal__featured-asset__link"
                >
                  <ImageWithFallback
                    customClass="show-row-preview-modal__featured-asset__image"
                    isBroken={hasBrokenImage}
                    onBrokenImage={onBrokenImage}
                    path={asset.mezzanine16x9ImageUrl}
                    crop="?format=auto&crop=350x197"
                    crop2x="?format=auto&crop=700x394"
                    textOverlay={false}
                    title={asset.title}
                  />
                  {asset.isMvod && (
                    <img
                      className="pbs-passport-compass-rose"
                      src={`${window.PBS_STATIC_URL}svg/pbs-passport-compass-rose--corner.svg`}
                      alt="Passport"
                      width="32"
                      height="32"
                    />
                  )}
                </a>
              </div>
              <div className="show-row-preview-modal__featured-asset__details">
                <h3 className="show-row-preview-modal__featured-asset__title">
                  <a
                    href={`/video/${asset.slug}/`}
                    className="show-row-preview-modal__featured-asset__video-title-link"
                    data-gtm-position="1"
                  >
                    {asset.title}
                  </a>
                </h3>
                <p className="show-row-preview-modal__featured-asset__meta-data">
                  {asset.ordinalDisplay} | {formatDuration(asset.duration)}
                </p>
                <p className="show-row-preview-modal__featured-asset__video-description">
                  {asset.descriptionShort}
                </p>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}
