/**
 * Checks to see if localStorage is supported / allowed
 * @returns {boolean}
 */
const isLocalStorageSupported = (): boolean => {
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Checks to see if sessionStorage is supported / allowed
 * @returns {boolean}
 */
const isSessionStorageSupported = (): boolean => {
  const test = 'test';
  try {
    sessionStorage.setItem(test, test);
    sessionStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * utility function that tests for storage *availability* (not just support)
 * this helps us handle when users have turned local storage off, for instance.
 * adapted from https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Feature-detecting_localStorage
 * usage: if (storageAvailable('localStorage')) { ....
 * @param {string} type e.g. 'localStorage'
 * @returns {boolean}
 */
const storageAvailable = (type: string): boolean => {
  const x = '__storage_test__';
  let storage;
  try {
    storage = window[type];
  } catch (e) {
    if (e.name !== 'SecurityError') {
      // swallowing Safari's "Block all cookies" error 
    } else {
      return e
    }
  }
  try {
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage.length !== 0
    );
  }
};

/**
 * Gets an item from localStorage
 * @param {string} key
 * @returns {string}
 */
const getItem = (key: string): string | null => {
  try {
    return window.localStorage.getItem(key);
  } catch (e) {
    return null;
  }
};

/**
 * Sets an item in localStorage
 * @param {string} key
 * @param {string} value
 * @returns {void}
 */
const setItem = (key: string, value: string): void => {
  try {
    return window.localStorage.setItem(key, value);
  } catch (e) {
    return;
  }
};

/**
 * Parses an item in localStorage
 * @param {string} key
 * @returns {string}
 */
function getItemValue(key: string): string | boolean {
  return JSON.parse(getItem(key));
}

/**
 * Sets a parsed item in localStorage
 * @param {string} key
 * @param {string} value
 * @returns {void}
 */
function setItemValue(key: string, value: string): void {
  setItem(key, JSON.stringify(value));
}

export {
  storageAvailable,
  isLocalStorageSupported,
  isSessionStorageSupported,
  getItem,
  setItem,
  getItemValue,
  setItemValue,
};
