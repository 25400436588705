import Cookies from 'js-cookie';

import { customEvents } from 'scripts/custom-events';
import * as User from 'scripts/modules/personal';
import { getCookieDomain } from 'scripts/utils/getCookieDomain';
import { isComingFromSigningIn } from 'scripts/utils/isComingFromSigningIn';
import { getUserId } from 'scripts/utils/getUserId';
import { MAX_MY_LIST_SIZE } from 'components/my-list/my-list-button/utils/constants';
import { init as continueWatchingInit } from 'components/continue-watching/continue-watching';
import { logUidDate } from 'scripts/utils/logUidDate';

/**
 * This file is frankly pretty messy - a lot of concerns are mixed in here.
 * A lot of the user data/cookie stuff isn't really relevant to the page header,
 * and this file is invoked by page-header.
 * @TODO separate out the general, broad functionality of this file into it's own file
 */

interface CacheExpectations {
  body?: HTMLBodyElement;
  signInButton?: HTMLElement;
  signedInAsLabel?: HTMLElement;
  continueWatchingShowRow?: HTMLElement;
  continueWatchingSections?: NodeListOf<HTMLElement>;
  userDropdownContent?: NodeListOf<HTMLElement>;
  userDropdown?: HTMLElement;
  usernames?: NodeListOf<HTMLElement>;
  profileImageContainer?: HTMLElement;
  profileImage?: HTMLImageElement;
  passportProfileImage?: HTMLImageElement;
  homepageRow?: HTMLElement;
  videoExpiration?: HTMLElement;
  recEngineCustomizedTitle?: HTMLElement;
}
/**
 * Caches reuseable elements.
 * @type {CacheExpectations} cache
 */
const cache: CacheExpectations = {};

/**
 * We use this class in a lot of places
 * @type {string} defaultMvodCookie
 */
const hiddenClass = 'is-hidden';

/**
 * Config for default cookie values.
 * @type {object} defaultMvodCookie
 */
const defaultMvodCookie: Cookies.CookieAttributes = {
  expires: 0,
  path: '/',
  domain: getCookieDomain(),
  secure: true,
  sameSite: 'Lax',
};

/**
 * Sets up cache of of resuable elements.
 */
const setupCache = (): void => {
  cache.body = document.querySelector('body');
  cache.homepageRow = document.querySelector('.homepage-row');
  cache.signInButton = document.querySelector('#userDropdownLoggedOut');
  cache.signedInAsLabel = document.querySelector('#signedInAsLabel');
  cache.videoExpiration = document.querySelector('#videoExpirationVariable');
};

/**
 * Adds references to DOM elements relating to the signed-in user experience.
 */
const setupSignedInCache = (): void => {
  cache.continueWatchingShowRow = document.getElementById(
    'continue-watching-show-row--user-menu'
  );
  cache.continueWatchingSections =
    document.querySelectorAll('.continue-watching');
  cache.userDropdownContent = document.querySelectorAll(
    '#userDropdown, .user-dropdown__items, .user-dropdown__links, .user-dropdown, #signedInAsLabel'
  );
  cache.userDropdown = document.querySelector('.user-dropdown');
  cache.usernames = document.querySelectorAll('.user-name');
  cache.profileImageContainer = document.querySelector(
    '.header__image-container'
  );
  cache.profileImage =
    cache.profileImageContainer.querySelector('.user-info__image');
  cache.passportProfileImage = document.querySelector(
    '.passport-logo-image__container'
  );
  cache.recEngineCustomizedTitle = document.querySelector(
    '.home #recommendation-engine-show-row .show-row__header span'
  );
};

/**
 * Shows the 'Sign in' button in the header.
 */
const showHeaderSignInButton = (): void => {
  cache.signInButton.classList.remove(hiddenClass);
  cache.signedInAsLabel.classList.add(hiddenClass);
};

/**
 * Enables the user dropdown in the header to be seen.
 */
const enableUserDropdown = (): void => {
  cache.userDropdownContent.forEach((item: HTMLElement) => {
    item.classList.remove(hiddenClass);
  });
};

interface Video {
  duration?: number;
  image?: string;
  seconds_watched?: number | null;
  slug?: string;
  title?: string;
  url?: string;
  percent_complete?: number;
  parent?: {
    season?: {
      show?: {
        slug?: string;
        title?: string;
      };
    };
  };
}

interface Show {
  slug?: string;
}

interface UserData {
  is_passport?: boolean;
  stations?: string;
  username: string;
  thumbnail_URL: string;
  user_passport_video?: {
    has_expire_date: boolean;
    expire_date: string;
  };
  resume_watching?: {
    content: Array<Video>;
  };
  favorite_shows?: {
    content: Array<Show>;
  };
  favorite_videos?: {
    content: Array<Video>;
  };
}

/**
 * Updates passport cookies based on data when user signs in.
 * @param {object} data - user data
 */
const updatePassportCookies = (data: UserData): void => {
  const mvodCookie = Cookies.get('pbs_mvod');

  if (data.is_passport && !mvodCookie) {
    const cookie_config = Object.assign({}, defaultMvodCookie, {
      expires: 30,
    });

    Cookies.set('pbs_mvod', data.stations, cookie_config);
  } else if (!data.is_passport && mvodCookie) {
    Cookies.set('pbs_mvod', null, defaultMvodCookie);
  }
};

/**
 * Updates username and thumbnail based on data when user signs in.
 * @param {object} data - user data
 */
const updateNameAndThumbnail = (data: UserData): void => {
  const { username, thumbnail_URL } = data;

  // update name
  cache.usernames.forEach((userName) => (userName.innerHTML = username));

  // if there is a thumbnail image, update src
  if (thumbnail_URL) {
    cache.profileImage.src = thumbnail_URL;
  } else {
    cache.profileImageContainer.remove();
  }

  // if is passport, show passport icon
  // display smaller passport logo in corner of profile image if profile image exists
  // if no profile image, just display passport logo on its own
  if (data.is_passport) {
    cache.passportProfileImage.classList.remove(hiddenClass);
  }
};

/**
 * Check is video page and updates DOM.
 * @param {object} data - user data returned from request.
 * @todo - reassass how we handle page level on load functionality
 */
const updateVideoPage = (data: UserData): void => {
  if (cache.videoExpiration && data.user_passport_video) {
    if (data.user_passport_video.has_expire_date) {
      const now = new Date();
      const expireDate = new Date(data.user_passport_video.expire_date);
      const expirationText = now < expireDate ? 'Expires' : 'Expired';

      // new expire date, replace content
      cache.videoExpiration.innerHTML = `${expirationText}: ${data.user_passport_video.expire_date}`;
      cache.videoExpiration.classList.remove(hiddenClass);
    } else {
      // never expires, remove whole Expire section
      cache.videoExpiration.classList.add(hiddenClass);
    }
  }
};

/**
 * Creating custom events
 */
const userIsNotLoggedInEvent = new CustomEvent(customEvents.userIsNotLoggedIn);
const userIsNotPassportEvent = new CustomEvent(customEvents.userIsNotPassport);
const userIsPassportEvent = new CustomEvent(customEvents.userIsPassport);
const userStatusIsKnownEvent = new CustomEvent(customEvents.userStatusIsKnown);
const userIsNotPassportAndIsSigningInEvent = new CustomEvent(
  customEvents.userIsNotPassportAndIsSigningIn
);

/**
 * Updates all of the places on a page with user-dynamic data.
 * @param {object} data - data returned from request
 */
const updateLayoutWithUserData = (data: UserData): void => {
  cache.body.classList.add('user-is-signed-in');

  // updates username and thumbnail
  updateNameAndThumbnail(data);

  if (cache.continueWatchingShowRow) {
    // this inits both the home page Continue Watching show row
    // as well as the abbreviated set of posters in the user menu
    continueWatchingInit();
  }

  // show dropdown
  enableUserDropdown();

  // for video page, update expiration
  if (cache.body.classList.contains('video')) {
    updateVideoPage(data);
  }

  // RWEB-7834 add custom title to Rec Engine show row with user name
  if (cache.recEngineCustomizedTitle) {
    const customTitle = `Top Picks for ${data.username}`;
    cache.recEngineCustomizedTitle.innerHTML = customTitle;
  }
};

/**
 * When /personal request is done.
 * @param {object} data - data returned from request.
 */
const onUserRequestDone = (data: UserData): void => {
  window.PBS = window.PBS || {};
  window.PBS.personalData = data;
  // update passport cookie
  updatePassportCookies(data);

  logUidDate('loginSuccess');

  // if data has username
  if (data.username) {
    // setup cache for items related to signed in
    setupSignedInCache();
    // update page layout based on data
    updateLayoutWithUserData(data);

    // triggers a window event that the user is passport
    if (data.is_passport) {
      window.dispatchEvent(userIsPassportEvent);
      window.dispatchEvent(userStatusIsKnownEvent);
    } else {
      window.dispatchEvent(userIsNotPassportEvent);
      window.dispatchEvent(userStatusIsKnownEvent);
      // if they are not passport, and are coming from logging in
      // see passport-modal.js for usage
      if (isComingFromSigningIn()) {
        window.dispatchEvent(userIsNotPassportAndIsSigningInEvent);
      }
    }
  } else {
    window.dispatchEvent(userIsNotLoggedInEvent);
    window.dispatchEvent(userStatusIsKnownEvent);
    // otherwise, show sign in button again
    if (navigator.cookieEnabled) {
      showHeaderSignInButton();
    }
  }

  // set two flags for maxed-out favorite shows and watchlists
  const favoriteShowsFull =
    data.favorite_shows &&
    data.favorite_shows.content &&
    data.favorite_shows.content.length >= MAX_MY_LIST_SIZE;
  const watchlistFull =
    data.favorite_videos &&
    data.favorite_videos.content &&
    data.favorite_videos.content.length >= MAX_MY_LIST_SIZE;
  if (favoriteShowsFull) {
    window.PBS.personalData.shows_full = favoriteShowsFull;
  }
  if (watchlistFull) {
    window.PBS.personalData.videos_full = watchlistFull;
  }

  // clears localStorage after the user logs in
  if (isComingFromSigningIn()) {
    localStorage.removeItem('tryingToLogin');
  }
  // if, in future we need to fire an event when user data is loaded,
  // it should go here
};

/**
 * On /personal request error.
 * Error thrown in personal.ts in sendRequest
 */
const onRequestError = (err: Error): void => {
  if (navigator.cookieEnabled) {
    showHeaderSignInButton();
  }

  logUidDate('gettingDataFailure');

  // if we get a 404, that means the user session has expired,
  // or that CS doesn't know about the user
  if (err.message === '404') {
    // clear the uid cookie in this case
    Cookies.remove('pbs_uid', {
      path: '/',
      domain: getCookieDomain(),
    });
    // and clear the pbs_uid_date cookie
    Cookies.remove('pbs_uid_date', {
      path: '/',
      domain: getCookieDomain(),
    });
    // reload the page
    window.location.reload();
  } else {
    // handling other errors:
    // indicate that the request to /personal/ failed
    // if personalData is null, we infer user is NOT signed in
    window.PBS = window.PBS || {};
    window.PBS.personalData = null;
    window.dispatchEvent(userIsNotLoggedInEvent);
    window.dispatchEvent(userStatusIsKnownEvent);
  }
};

/**
 * Initializes user onload module.
 */
const init = (): void => {
  setupCache();
  // if we find the user id cookie
  if (getUserId()) {
    User.sendRequest()
      .then((response) => {
        if (response.status !== 200) {
          throw new Error('bad server resonse');
        }
        return response.json();
      })
      .then((data) => onUserRequestDone(data))
      .catch((err) => onRequestError(err));
  } else {
    logUidDate('userIsNotLoggedIn');

    // we set the user's personal data to null on the window.PBS object
    // this is needed for RWEB-6361 to better verify a user's sign-in status
    window.PBS = window.PBS || {};
    window.PBS.personalData = null;
    window.dispatchEvent(userIsNotLoggedInEvent);
    window.dispatchEvent(userStatusIsKnownEvent);

    // set cookie
    if (Cookies.get('pbs_mvod')) {
      Cookies.set('pbs_mvod', null, defaultMvodCookie);
    }

    // show sign in button
    if (navigator.cookieEnabled) {
      showHeaderSignInButton();
    }
  }
};

export { init };
