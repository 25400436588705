import React, { useState, Dispatch, SetStateAction } from 'react';
import { createRoot } from 'react-dom/client';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import 'whatwg-fetch';

import PosterWithMenu from 'components/continue-watching/poster-with-menu/poster-with-menu';
import ContinueWatchingUserMenuRow  from 'components/continue-watching/user-menu-row/user-menu-row';
import { arrowPath } from 'components/carousel-arrows/arrow-path';
import { ReactComponent as TitleCaretRight } from 'svg/pbs-right-caret.svg';
import { getUserId } from 'scripts/utils/getUserId';
import { PBS_config } from 'scripts/modules/pbs-config';
import { Video, Show } from 'components/shared/content-services-types';

type CWData = Video[] | null;
type MLData = Show[] | null;
interface ContinueWatchingRowProps {
  continueWatchingData: CWData;
  myListData: MLData;
}

// Sends request for show row data and returns JSON
// (RequestInfo and Response are types defined by whatwg-fetch)
async function fetchData(request: RequestInfo) {
  const response = await fetch(request, {
    method: 'GET',
  });

  return await response.json();
}

// Returns a short meta summary of the video, e.g. S1 Ep2
// (We do not need to consider clips or previews; only full length assets)
const getVideoSummary = (video: Video): string => {
  let summary = ``;

  // if a special, label it as such
  if (video?.parent?.resource_type === 'special') {
    summary = 'Special';
  }

  // get season ordinal
  if (video?.parent?.season?.ordinal) {
    summary = `S${video.parent.season.ordinal} `;
  }

  // get episode ordinal
  if (video?.parent?.ordinal) {
    summary += `Ep${video.parent.ordinal}`
  }

  // if nothing has worked so far, use a default summary
  if (!summary) {
    summary = 'Watch';
  }
  return summary;
}

// This visually hides the removed slide for a better UX;
// the call to Profile Service will have taken effect by the time we
// render the list next time (we must hope)
const forceHideSlide = (customClass: string) => {
  const slide = document.querySelector(`.${customClass}`);
  slide.classList.remove('is-visible');
  slide.classList.add('is-hidden');
}

const createPosterSlides = (continueWatchingData: CWData, myListData: MLData, posterWithOpenMenu: string, setPosterWithOpenMenu: Dispatch<SetStateAction<string>>): Array<React.ReactElement> => {
  const continueWatchingSlides = continueWatchingData.map((item, index: number) => {
    const customClass = `continue-watching-slide-${index}`;
    return (
      <SplideSlide key={item.cid} className={customClass}>
        <PosterWithMenu
          posterType="continue-watching"
          show={item.show}
          percentComplete={(item.seconds_watched / item.duration) * 100}
          videoSlug={item.slug}
          videoSummary={getVideoSummary(item)}
          videoCid={item.cid}
          hideParent={() => forceHideSlide(customClass)}
          posterWithOpenMenu={posterWithOpenMenu}
          setPosterWithOpenMenu={setPosterWithOpenMenu}
          posterGTMAction={`Continue Watching | Video Thumbnail | ${item.title} | ${index + 1}`}
          textLinkGTMAction={`Continue Watching | Video Text Link | ${item.title} | ${index + 1}`}
        />
      </SplideSlide>
    );
  });

  const myListSlides = myListData.map((item, index: number) => {
    const customClass = `my-list-slide-${index}`;
    return (
      <SplideSlide key={item.cid} className={customClass}>
        <PosterWithMenu
          posterType="my-list"
          show={{
            'slug': item.slug,
            'title': item.title,
            'show-poster2x3': item.images['show-poster2x3']
          }}
          videoSummary="Watch" // no metadata available yet
          showCid={item.cid}
          hideParent={() => forceHideSlide(customClass)}
          posterWithOpenMenu={posterWithOpenMenu}
          setPosterWithOpenMenu={setPosterWithOpenMenu}
          posterGTMAction={`Continue Watching | Show Thumbnail | ${item.title} | ${index + 1}`}
          textLinkGTMAction={`Continue Watching | Show Text Link | ${item.title} | ${index + 1}`}
        />
      </SplideSlide>
    );
  });

  const posterSlides = continueWatchingSlides.concat(myListSlides);

  return posterSlides;
}

const ContinueWatchingRow: React.FC<ContinueWatchingRowProps> = (props) => {
  const { continueWatchingData, myListData } = props;

  // keep tabs on which poster, if any, has an open menu
  // this way we can ensure only one is open at a time - RWEB-7871
  const [posterWithOpenMenu, setPosterWithOpenMenu] = useState('');

  const posterSlides = createPosterSlides(
    continueWatchingData,
    myListData,
    posterWithOpenMenu,
    setPosterWithOpenMenu
  );

  return (
    <div className="react-show-row show-row theme-lightest-darkest">
      <div className="show-row__header">
        <h2 className="content-section-title">
          <a
            href="/my-list-viewing-history/"
            className="content-section-title__link"
          >
            <span>Continue Watching</span>
            <TitleCaretRight />
          </a>
        </h2>
      </div>
      <Splide
        options={{
          arrowPath: arrowPath, // using our custom caret SVG in splide's built-in arrows
          breakpoints: {
            [PBS_config.breakpoints.splide.md]: {
              perPage: 5,
            },
            [PBS_config.breakpoints.splide.sm]: {
              perPage: 4,
            },
            [PBS_config.breakpoints.splide.smaller]: {
              perPage: 3,
            },
          },
          classes: {
            arrow: 'carousel__arrow',
            prev: 'splide__arrow--prev carousel-prev',
            next: `splide__arrow--next carousel-next`,
          },
          drag: true,
          gap: '8px',
          pagination: false,
          perPage: 6,
          rewind: false,
          slideFocus: false,
        }}
      >
        {posterSlides}
      </Splide>
    </div>
  );
}

const init = (): void => {
  let continueWatchingData: CWData = null;
  let myListData: MLData = null;

  // Get the user ID. We won't render the component unless they're signed in
  const userId = getUserId();

  // Check that the DOM element exists before trying to mount the component.
  const mountPoint = document.getElementById('continue-watching-show-row');
  // Also check for the DOM element in the user menu.
  const mountPointUserMenu = document.getElementById('continue-watching-show-row--user-menu');

  // If the user is signed in and the more-often used mount point exists, fetch the data
  if (userId && mountPointUserMenu) {
    // internal API to shield CS endpoint creds from LastPass (RWEB-7906)
    const continueWatchingEndpoint = `/personal/continue-watching/`;
    const myListEndpoint = `/personal/my-list-shows/`;

    fetchData(continueWatchingEndpoint).then(response => {
      continueWatchingData = response?.collections[0]?.content;
      return fetchData(myListEndpoint);
    }).then((response) => {
      myListData = response?.collections[0]?.content;
    }).then(() => {
      // If we successfully fetched both sets of data & the show row mount point exists, we can render
      if (continueWatchingData && myListData && mountPoint) {
        const root = createRoot(mountPoint!);
        root.render(
          <ContinueWatchingRow
            continueWatchingData={continueWatchingData}
            myListData={myListData}
          />
        );
      }

      // If we have at least Continue Watching data & the user menu mount point exists, we can render
      if (continueWatchingData && mountPointUserMenu) {
        // add a class to the user dropdown itself, which helps with styling
        const userDropdown = document.querySelector('.user-dropdown');
        userDropdown.classList.add('has-continue-watching');
        const root = createRoot(mountPointUserMenu!);
        // use only the first three items
        const abbreviatedContinueWatchingData = continueWatchingData.slice(0, 3);
        root.render(
          <ContinueWatchingUserMenuRow
            posterData={abbreviatedContinueWatchingData}
            getVideoSummary={getVideoSummary}
          />
        );
      }
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.error('Error fetching data: ', err)
    });
  }
};

export { init };
