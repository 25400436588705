import React from 'react';
import { createRoot } from 'react-dom/client';

import { ShowRowPreviewContainer } from './components/container';

interface CacheExpectations {
  showRowPosters?: NodeListOf<HTMLElement>;
  showListPosters?: NodeListOf<HTMLElement>;
}

const cache: CacheExpectations = {};

/**
 * Caches re-used elements.
 */
const setupCache = () => {
  cache.showRowPosters = document.querySelectorAll(
    '.show-row .show-poster'
  );
  cache.showListPosters = document.querySelectorAll(
    '.shows-list .shows-list__item'
  );
}

/**
 * Adds necessary event listeners.
 */
const addEventListeners = () => {
  cache.showRowPosters.forEach((showRowPoster) => {
    showRowPoster.addEventListener('mouseover', onShowPosterHover)
  });
  cache.showListPosters.forEach((showListPoster) => {
    showListPoster.addEventListener('mouseover', onShowPosterHover)
  })
}

/**
 * Handles each show poster hover event.
 */
const onShowPosterHover = (e: MouseEvent) => {
  const poster = (e.currentTarget as HTMLElement);
  const previewButtonPlaceholder = (poster.querySelector('.show-row__preview-btn-placeholder') as HTMLElement);

  // check if the placeholder element is there (it is for shows, but not for franchises)
  // and check if we have already fetched data for this show poster
  // if we haven't, initialize the show preview container
  if (previewButtonPlaceholder && !poster.classList.contains('fetched-data')) {
    // render the component in the placeholder element
    const root = createRoot(previewButtonPlaceholder!);
    root.render(
      <ShowRowPreviewContainer
        title={previewButtonPlaceholder.dataset.title}
        showSlug={previewButtonPlaceholder.dataset.showSlug}
      />
    );
    // now indicate that we've fetched the data for this show poster
    poster.classList.add('fetched-data');
  }
}

/**
 * Initializes Show Row Preview components.
 */
const initializeShowRowPreviews = (): void => {
  setupCache();
  addEventListeners();
}

export { initializeShowRowPreviews };
