import * as Profile from 'scripts/modules/profile';

// This function keeps a local record of the current state of the user's
// List so that the front-end mirrors their actions in real time (see
// comment on editProfileData() above). It updates the user's personalData
// object, which is stored in window.PBS.personalData. See user-onload.js.
const editWindowData = (operation: 'add' | 'remove', contentType: 'video' | 'show', slug: string) => {
  const favoritesArrayKey =
    contentType === 'show' ? 'favorite_shows' : 'favorite_videos';
  // cloning the profile data
  const favoritesArray = window.PBS.personalData[
    favoritesArrayKey
  ].content.slice();

  if (operation === `remove`) {
    for (let i = 0; i < favoritesArray.length; i++) {
      // if the slug of this video matches the passed id, we remove it from the array with .splice
      if (favoritesArray[i].id === slug) {
        favoritesArray.splice(i, 1);
      }
    }
  } else if (operation === `add`) {
    // tacking on the new favorite at the end of the array
    favoritesArray.push({ id: slug });
  }

  // actually overwriting the array with the altered one
  window.PBS.personalData[favoritesArrayKey].content = favoritesArray;
};

// This function formats the data to be sent via profile.js
// to add and remove items from users' Lists. This operation
// can take a few minutes to take effect, so we have another
// function below, editWindowData(), to handle local behavior.
const editProfileData = (operation: 'add' | 'remove', cid: string, contentType: 'video' |
'show', slug: string): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const key = contentType === 'show' ? 'favoriteShow' : 'favoriteVideo';
    Profile[operation](key, {
      id: cid
    })
      .then(() => {
        resolve();
        editWindowData(operation, contentType, slug);
      })
      .catch((err) => {
        reject(new Error(`A call to /profile/ failed: ${err.status}`));
      });
  });
}

export { editProfileData }
