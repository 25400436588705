// Because these customEvents are fired within our components,
// and other places listen for them, we are declaring the event name strings
// themselves here and sharing this object so that it's
// harder to get the strings wrong, and it's more clear that something
// is a custom event shared within the codebase.
// Adapted from this article https://medium.com/codex/how-to-share-constants-in-typescript-project-8f76a2e40352
export const customEvents = {
  timezoneToggle: `timezoneToggle`,
  userAppearancePrefChange: `userAppearancePrefChange`,
  userIsNotLoggedIn: `userIsNotLoggedIn`,
  userIsPassport: `userIsPassport`,
  userIsNotPassport: `userIsNotPassport`,
  userIsNotPassportAndIsSigningIn: `userIsNotPassportAndIsSigningIn`,
  userStatusIsKnown: `userStatusIsKnown`,
  userViewingHistoryLoaded: `userViewingHistoryLoaded`,
} as const;
