import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';

import { init as videoSummariesInit } from 'components/video/summary/video';
import { arrowPath } from 'components/carousel-arrows/arrow-path';

interface SvpHeroVideosCache {
  svpHeroVideos?: HTMLElement;
  [key: string]: Splide | HTMLElement;
}

const cache: SvpHeroVideosCache = {};

/**
 * Caches re-used elements
 */
const setupCache = () => {
  cache.svpHeroVideos = document.querySelector(
    '.svp-hero__splide'
  );
};

/**
 * Sets up each SVP Hero video row instance.
 */
const setupSvpHeroVideos = () => {
  if (cache.svpHeroVideos) {
    // init the video summaries (apply tooltips and popovers)
    videoSummariesInit(cache.svpHeroVideos);
    cache[`carousel`] = new Splide(cache.svpHeroVideos, {
      arrowPath: arrowPath,
      // remember options values are for *below* these numbers
      breakpoints: {
        1024: {
          perPage: 4,
        },
        768: {
          gap: '14px',
          perPage: 2,
        },
        480: {
          perPage: 1,
        }
      },
      classes: {
        arrow: 'carousel__arrow',
        prev: 'splide__arrow--prev carousel-prev',
        next: `splide__arrow--next carousel-next`,
      },
      // 1024 and *above*
      gap: '16px',
      pagination: false,
      perPage: 4,
    }).mount();
  }
};

/**
 * Initializes component.
 */
const init = (): void => {
  setupCache();
  setupSvpHeroVideos();
};

export { init };
