import React from 'react';

import MyListButton from 'components/my-list/my-list-button/components/button';

export const ShowRowPreviewDetails: React.FC<ShowRowPreviewDetailsProps> = (props) => {
  const { cid, descriptionLong, whiteLogo4x1ImageUrl, slug, title } = props;

  return (
    <div className="show-row-preview-modal__show-content">
      <div className="show-row-preview-modal__show-content__info">
        <h1 className="show-row-preview-modal__show-content__info__title">
          <a className="show-row-preview-modal__show-content__info__title-link" href={`/show/${slug}/`}>
            {whiteLogo4x1ImageUrl ? (
              <img className="show-row-preview-modal__show-content__info__logo"
                src={whiteLogo4x1ImageUrl}
                alt={title} />
            ) : (
              <span className="show-row-preview-modal__show-content__info__title-text">{title}</span>
            )}
          </a>
        </h1>
        <p className="show-row-preview-modal__show-content__info__broadcast-info">{/*Broadcast Info to go here*/}</p>
        <p className="show-row-preview-modal__show-content__info__description">
          {descriptionLong}
        </p>
      </div>
      <div className="show-row-preview-modal__show-content__buttons">
        <a
          className="show-row-preview-modal__show-content__all-episodes-btn btn btn--fill--blue"
          href={`/show/${slug}/`}
        >
          Explore the Show
        </a>
        <MyListButton
          gtmLabel={title}
          cid={cid}
          contentType="show"
          slug={slug}
          customAddText="Add to My List"
          customRemoveText="Remove from My List"
        />
      </div>
    </div>
  );
}
