import React, { useState, useEffect } from 'react';

import { getPersonalData } from 'scripts/utils/getPersonalData';
import {
  processPersonalData,
  itemIsInList,
  handleMaxedOutList,
  handleLoggedOutUser,
} from '../utils/utils';
import { editProfileData } from '../utils/editProfileData';
import { ReactComponent as AddIcon } from 'svg/pbs-add.svg';
import { ReactComponent as CheckmarkIcon } from 'svg/pbs-check.svg';
import { MyListButtonProps, PersonalData } from '../types/my-list-button';

const MyListButton: React.FC<MyListButtonProps> = (props) => {
  const { cid, customAddText, customRemoveText, slug, contentType, gtmLabel } = props;
  const [selected, setSelected] = useState(false);
  const [personalData, setPersonalData] = useState(processPersonalData(null));

  // On first load, get personal data from window.PBS.personalData
  useEffect(() => {
    getPersonalData()
      .then((data: PersonalData) => {
        const processedData = processPersonalData(data);
        setPersonalData(processedData);
      })
      .catch(() => {
        // catching errors here for node environments
        // so that tests pass without warnings
      })
  }, []);

  // When we have personal data, determine if the button
  // should display as selected or unselected
  useEffect(() => {
    const { isSignedIn, showsList, videosList } = personalData;

    // For signed in users, we check their List(s) to see if they
    // already contain the slug corresponding to this show/video.
    const hasMatch = () => {
      // if the user is signed in
      if (isSignedIn) {
        if (
          // and the slug is in either their shows list
          (showsList && itemIsInList(slug, showsList)) ||
          // or their videos list
          (videosList && itemIsInList(slug, videosList))
        ) {
          return true;
        }
      }
      return false;
    };

    // If they do, the button should display as selected.
    if (isSignedIn && hasMatch()) {
      setSelected(true);
    } else {
      // All buttons should be unselected when users are not signed in
      setSelected(false);
    }
  }, [personalData, slug]);

  const handleClicks = () => {
    const { showsFull, videosFull, isSignedIn } = personalData;
    const tooManyShows = !selected && showsFull && contentType === 'show';
    const tooManyVideos = !selected && videosFull && contentType === 'video';
    // Note: these correspond to supported operations in profile.js
    const operation = selected ? `remove` : `add`;

    if (tooManyShows || tooManyVideos) {
      handleMaxedOutList(cid, contentType, slug, setSelected);
    } else if (isSignedIn) {
      editProfileData(operation, cid, contentType, slug)
        .then(() => {
          setSelected(!selected);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    } else {
      handleLoggedOutUser(cid, contentType);
    }
  };

  // use .an-33_1 for shows, .an-32_1 for videos
  const analyticsClass = contentType === `show` ? `an-33_1` : `an-32_1`;
  const customText = selected ? customRemoveText : customAddText;

  return (
    <button
      className={`btn btn--mylist ${selected ? 'selected' : ''} ${analyticsClass}`}
      data-gtm-label={gtmLabel}
      data-cid={cid}
      onClick={() => handleClicks()}
    >
      {selected ? (<CheckmarkIcon />) : (<AddIcon />)}
      <span className="btn--mylist__hidden-text visuallyhidden">
        {selected ? 'Remove from' : 'Add to'}
      </span>
      <span className="btn--mylist__visible-text">{customText ? customText : 'My List'}</span>
    </button>
  );
};

export default MyListButton;
