/*
  <ProgressBar />
*/

import React from 'react';

interface ProgressBarProps {
  percentComplete: number;
}

const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  const { percentComplete } = props;

  const progressStyles = {
    width: `${percentComplete}%`,
  };

  return (
    <div className="progress" data-testid="rtl-progressBar">
      <div className="progress-bar" role="progressbar" style={progressStyles} />
    </div>
  );
};

export default ProgressBar;
