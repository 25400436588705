import React from 'react';
import { createRoot } from 'react-dom/client';

import Time from 'components/shared/react-time';
interface CacheExpectations {
  scheduleTimes?: NodeListOf<HTMLTimeElement>;
}

const cache: CacheExpectations = {};

/**
 * Caches re-used elements.
 * @returns {function} setupCache
 */
const setupCache = (schedule: HTMLElement) => {
  cache.scheduleTimes = schedule.querySelectorAll('.schedule__time');
};

const renderTime = () => {
  const timesArray = Array.from(cache.scheduleTimes);
  timesArray.forEach((time) => {
    // ie11 doesn't understand .dateTime, so this falls back to a data attribute
    // this way browsers that do understand it can use it
    const dateTime = time.dateTime ? time.dateTime : time.dataset.dateTime;
    const secondaryTimezone = time.dataset.secondaryTimezone;
    const root = createRoot(time!);
    root.render(
      <Time
        dateTime={dateTime}
        secondaryTimezone={secondaryTimezone ? secondaryTimezone : null}
        className="schedule__time__string"
        format="LT"
      />
    );

    time.classList.add('schedule__time--formatted');
  });
};

/**
 * Initializes component.
 * @returns {function} _init
 */
const init = (schedule: HTMLElement): void => {
  // testing to make sure there is a schedule to work on
  if (schedule) {
    setupCache(schedule);
    renderTime();
  }
};

export { init };
