import queryString from 'query-string';
import * as SignInModal from '../../sign-in/sign-in';
import * as SideNav from './navigation-side';
import Modal from '../../modal/modal';

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

interface ModalInterface {
  show: (arg0?: CustomParams | Record<string, unknown>) => void;
}

let signInModal: ModalInterface;

const setupSignInModal = () => {
  const options = {
    modalId: '#signInModalWindow',
    modalTrigger: '.btn--sign-in',
    focusTarget: '#sign-in__dialog',
    lastFocusableEl: '#signInModalWindow .close-modal',
    childView: SignInModal,
  };

  signInModal = new Modal(options);
};

interface CacheExpectations {
  signInButton?: HTMLButtonElement;
  signOutButton?: HTMLButtonElement;
}

const cache: CacheExpectations = {};

const setupCache = () => {
  cache.signInButton = document.querySelector('.js-user-dropdown__sign-in');
  cache.signOutButton = document.querySelector('button.js-user-dropdown__sign-out');
};

interface CustomParams {
  code: string,
  action: string,
  type: string,
  url: string,
}
/**
 * Shows sign in modal.
 */
const showModal = (customParams?: CustomParams) => {
  const data = customParams || {};

  if (signInModal) {
    signInModal.show(data);
  }
};

/**
 * Applies a popover to the sign in button
 */
const setupSignInPopover = () => {
  const popover = document.createElement('div');
  popover.innerHTML = cache.signInButton.getAttribute('data-popover');
  popover.classList.add('popover');
  popover.classList.add('popover--sign-in');

  tippy(cache.signInButton, {
    content: popover,
    delay: [100, 100],
    interactive: true,
    maxWidth: 315,
    placement: 'bottom',
    theme: 'light',
    touch: false,
  });
};

/**
 * When 'Sign In' is clicked in navigation.
 * @param {event} e
 */
const onSignInClick = (e: Event) => {
  e.preventDefault();
  showModal();
  SideNav.hideNav();
};

/**
 * When 'Sign Out' is clicked in navigation.
 * @param {event} e
 */
const onSignOutClick = (e: Event) => {
  e.preventDefault();
  SignInModal.signOut();
};

/**
 * Adds events for this module.
 */
const addEvents = () => {
  cache.signOutButton?.addEventListener('click', onSignOutClick);
  cache.signInButton.addEventListener('click', onSignInClick);
};

/**
 * Checks for re-direct and if we need to open the sign-in modal.
 */
const checkForMvodRedirect = () => {
  const params = queryString.parse(window.location.search);

  if (params.showSignIn === 'true' && params.returnURL) {
    showModal({
      code: 'MVOD',
      action: 'Upsell screen',
      type: 'sign-in',
      url: params.returnURL as string,
    });
  }
};

/**
 * On init.
 */
const init = (): void => {
  setupCache();
  addEvents();
  setupSignInModal();
  setupSignInPopover();
  checkForMvodRedirect();
};

export { init };
