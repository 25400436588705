import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';

import { arrowPath } from 'components/carousel-arrows/arrow-path';
import { hideShowNextArrow } from 'components/carousel-arrows/hide-show-next-arrow';
import { initializeShowRowPreviews } from './show-row-preview/show-row-preview';

import { customEvents } from 'scripts/custom-events';
import { PBS_config } from 'scripts/modules/pbs-config';

interface ShowRowCache {
  showRows?: NodeListOf<HTMLDivElement>;
  memberMessages?: NodeListOf<HTMLElement>;
  becomeAMemberButtons?: NodeListOf<HTMLElement>;
  learnMoreButtons?: NodeListOf<HTMLElement>;
  [key: string]: Splide | NodeListOf<HTMLElement> ; // TODO: find a way to add typed elements to the cache dynamically
}

const cache: ShowRowCache = {};

/**
 * Caches re-used elements
 */
const setupCache = () => {
  cache.showRows = document.querySelectorAll(
    '.show-row .carousel-container'
  );
  cache.memberMessages = document.querySelectorAll('.show-row__passport__thank-you');
  cache.becomeAMemberButtons = document.querySelectorAll('.show-row__passport__become-a-member');
  cache.learnMoreButtons = document.querySelectorAll('.show-row__passport__learn-more');
};

/**
 * Sets up each Show Row instance.
 */
const setupShowRows = () => {
  if (cache.showRows?.length > 0) {
    cache.showRows.forEach((carousel: HTMLElement, index: number) => {
      // first we make an id based on the index,
      // then we create a carousel and add it to the cache.
      // TS note: we have to cast the root element (a node) to the HTMLElement type
      // in order for the tsc compiler to accept it.
      const typedRoot = <HTMLElement>cache.showRows[index];
      cache[`carousel${index}`] = new Splide(typedRoot, {
        arrowPath: arrowPath,
        breakpoints: {
          [PBS_config.breakpoints.splide.md]: {
            perPage: 5,
          },
          [PBS_config.breakpoints.splide.sm]: {
            perPage: 4,
          },
          [PBS_config.breakpoints.splide.smaller]: {
            perPage: 3,
          },
        },
        classes: {
          arrow: 'carousel__arrow',
          prev: 'splide__arrow--prev carousel-prev',
          next: `splide__arrow--next carousel-next is-hidden`,
        },
        pagination: false,
        perPage: 6,
        throttle: 300,
        slideFocus: false,
      });

      const newCarousel = cache[`carousel${index}`] as Splide;
      hideShowNextArrow(newCarousel, typedRoot);
      newCarousel.mount();
    });
  }
};

/**
 * Handles differing component behavior once a user is
 * identified as a Passport member.
 */
 const onUserIsPassport = () => {
  // if a Passport member is already signed in, we update the
  // component to un-hide the Passport member specific elements
  cache.memberMessages.forEach((button) => button.classList.remove('is-hidden'));

  window.removeEventListener(customEvents.userIsPassport, onUserIsPassport);
}

/**
 * Handles differing component behavior once a user is
 * identified as not a Passport member or not signed in.
 */
const onUserIsDefault = () => {
  // un-hide all the page elements that ask the user to become a member
  cache.becomeAMemberButtons.forEach((button) => button.classList.remove('is-hidden'));
  cache.learnMoreButtons.forEach((button) => button.classList.remove('is-hidden'));
}

const addEvents = () => {
  window.addEventListener(customEvents.userIsPassport, onUserIsPassport);
  window.addEventListener(customEvents.userIsNotLoggedIn, onUserIsDefault);
  window.addEventListener(customEvents.userIsNotPassport, onUserIsDefault);
}

/**
 * Initializes component.
 */
const init = (): void => {
  setupCache();
  addEvents();
  setupShowRows();
  initializeShowRowPreviews();
};

export { init };
