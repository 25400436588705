import Cookies from 'js-cookie';
import _isPlainObject from 'lodash.isplainobject';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IAny = any;
interface AdOptions extends IAny {
  slot: Slot;
  loaded: boolean;
  div_id: string;
  el: ParentNode;
  isEmpty: boolean;
  network_id: string;
  unit: string;
  sizes: {
    mobile: Array<Array<number>>,
    tablet: Array<Array<number>>,
    desktop: Array<Array<number>>,
  }
}

interface AdInterface {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [key: string]: object;
  updateDefaults: (
    arg0: AdInterface,
    arg1: string | boolean | Record<string, unknown> | Array<Array<number>> | number | (() => string),
    arg2: AdOptions | string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    arg3?: object | string | boolean,
  ) => void;
}

const Ad = class Ad {
  slot: Slot;
  loaded: boolean;
  div_id: string;
  el: ParentNode;
  isEmpty: boolean;
  network_id: string;
  unit: string;
  sizes: {
    mobile: Array<Array<number>>,
    tablet: Array<Array<number>>,
    desktop: Array<Array<number>>,
  }

  /**
   * Initializes class instance.
   * param {object} options - any custom options passed in
   * @param {number} options.div_id - div id where to add ad to DOM (mandatory for ad to show)
   * @param {number} options.unit - page unit for ad (mandatory for ad to show)
   * @constructor
   */
  constructor(options: Defaults) {
    const defaults: Defaults = {
      loaded: false,
      slot: null,
      el: null,
      div_id: '',
      network_id: window.PBS.GOOGLE_DFP_DESKTOP,
      sizes: [],
      unit: '',
      responsive: false,
      type: null,
    };

    // loop through all defaults and set value to any options passed in
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.updateDefaults(this, defaults, options);

    this.onAdRendered = this.onAdRendered.bind(this);
  }

  /**
   * Updates defaults to override with options passed in and sets to this instance.
   * @param {object} ad - current ad instance
   * @param {object} defaults - default values
   * @param {object} options - options values passed in
   * @param {object} context - if nested object,
   * this context will be the higher level key (optional)
   * @todo investigate using Map
   */
  updateDefaults(ad: AdInterface, defaults: Defaults, options: AdOptions, context?: {[key: string]: string | boolean;}): void {
    // if no context passed in
    // then we are on the top level of the modal object
    const currentContext = context ? context : ad;

    // loop through defaults
    for (const key in defaults) {
      if (Object.prototype.hasOwnProperty.call(defaults, key)) {
        // if the value of the key is a nested object
        // use this function recursively to loop through again
        if (_isPlainObject(defaults[key])) {
          ad[key] = {};
          ad.updateDefaults(ad, defaults[key], options[key], ad[key]);
        } else {
          // update the keys value
          currentContext[key] =
            options && typeof options[key] !== 'undefined'
              ? options[key]
              : defaults[key];
        }
      }
    }
  }

  /**
   * Sets default slot definition.
   */
  // returning any here because we don't have an exhaustive type for googletag
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSlot(): any {
    return this.defineSlot()
      .setCollapseEmptyDiv(true)
      .addService(window.googletag.pubads());
  }

  /**
   * Gets the slot DOM element by slot id.
   * checks for slot and if not, uses the div_id passed in
   * @returns {HTML node}
   */
  getSlotElement(): ParentNode {
    const slotId = this.slot ? this.slot.getSlotElementId() : this.div_id;
    const slotEl = document.getElementById(slotId);

    if (!slotEl) return null;

    return slotEl.parentNode ? slotEl.parentNode : slotEl;
  }

  /**
   * Sets slot element.
   */
  setSlotElement(): void {
    this.el = this.getSlotElement();
  }

  /**
   * Defines an ad slot via google tag manager.
   * @returns {object} - googletag slot definition
   */
  // returning any here because we don't have an exhaustive type for googletag
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defineSlot(): any {
    return window.googletag.defineSlot(
      this.network_id + this.unit,
      this.sizes,
      this.div_id
    );
  }

  /**
   * Displays ad in ad slot.
   */
  display(): void {
    window.googletag.cmd.push(() => {
      window.googletag.display(this.div_id);
    });
  }

  /**
   * Checks if the user is signed in or not based on the presence of the 'pbs_uid' cookie.
   */
  isSignedIn(): boolean {
    const signedIn = Cookies.get('pbs_uid') ? true : false;
    return signedIn;
  }

  /**
   * Checks if the user is a Passport member or not based on the presence of the 'pbs_mvod' cookie.
   */
  isPassportMember(): boolean {
    const passportMember = Cookies.get('pbs_mvod') ? true : false;
    return passportMember;
  }

  /**
   * Adds custom param for user signed in state.
   */
  addSignedInCustomParam(): void {
    const isSignedIn = this.isSignedIn() ? 'yes' : 'no';

    window.googletag.pubads().setTargeting('pbsuser', isSignedIn);
  }

  /**
   * Adds custom param for user Passport status: 'yes', 'no', or 'loggedout'.
   * Note: we generally refer to "sign in"/"sign out" rather than "login"/"logout"
   * on the user-facing frontend, but the 'loggedout' string was requested by
   * the ad team, so we'll use their language in this case.
   */
  addPassportCustomParam(): void {
    const isSignedIn = this.isSignedIn();
    const isPassport = this.isPassportMember();
    let passportStatus;
    if (!isSignedIn) {
      passportStatus = 'loggedout';
    } else if (isPassport) {
      passportStatus = 'yes';
    } else {
      passportStatus = 'no';
    }

    window.googletag.pubads().setTargeting('passport', passportStatus);
  }

  /**
   * Adds custom param for station callsign.
   */
  addStationCustomParam(): void {
    window.googletag.pubads().setTargeting('station', window.PBS_STATION_CALLSIGN.toLowerCase());
  }

  /**
   * Registers slot and wraps it in a Promise.
   */
  registerSlot(): Promise<void> {
    return new Promise<void>((resolve) => {
      // set slot definition
      this.slot = this.setSlot();

      // add callback for when slot is rendered
      // to add class so text/link will be displayed
      window.googletag
        .pubads()
        .addEventListener('slotRenderEnded', this.onAdRendered);

      // add user signed in custom param
      this.addSignedInCustomParam();

      // add passport custom param
      this.addPassportCustomParam();

      // add station custom param
      this.addStationCustomParam();

      resolve();
    });
  }

  /**
   * When an ad is rendered.
   * @param {event} e
   * triggered by 'slotRenderEnded' event
   */
  onAdRendered(e: GoogleAdRenderedEvent): void {
    // for some reason, no event exists
    // or if already loaded
    if (!e || this.loaded) {
      return;
    }

    // if this isn't relevant to this ad
    if (this.div_id !== e.slot.getSlotElementId()) {
      return;
    }

    this.loaded = true;
    // sometimes ads come back empty
    this.isEmpty = e.isEmpty;
  }

  /**
   * Refreshes slot and makes call to load ad.
   */
  refreshSlot(): void {
    window.googletag.cmd.push(
      function () {
        window.googletag.pubads().refresh([this.slot]);
      }.bind(this)
    );
  }

  /**
   * Loads ad by calling .refresh() via refreshSlot()
   * needed because we want to lazyload some ads.
   */
  load(): void {
    // if already loaded, do nothing
    if (this.loaded) {
      return;
    }

    this.refreshSlot();
  }

  /**
   * Renders ad.
   */
  render(): Ad {
    if (window.googletag) {
      window.googletag.cmd.push(
        function () {
          // register slot definition
          this.registerSlot().then(
            function () {
              // then display the ad
              this.display();

              // set the el reference
              this.setSlotElement();

              // try and load!
              if (!this.loaded) {
                this.load();
              }
            }.bind(this)
          );
        }.bind(this)
      );
    }

    return this;
  }
};

export default Ad;
