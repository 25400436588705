import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import * as Profile from 'scripts/modules/profile';
import { ShowRowPreviewContainer } from 'components/show-row/show-row-preview/components/container';
import { ReactComponent as PlayIcon } from 'svg/pbs-play--no-circle.svg';
import { ReactComponent as PagesIcon } from 'svg/pbs-pages.svg';
import { ReactComponent as CloseIcon } from 'svg/pbs-close-white.svg';

interface MenuProps {
  show: {
    'slug': string;
    'title': string;
    'show-poster2x3': string;
  };
  watchUrl: string;
  menuText: string;
  removeText: string;
  layout: string;
  closeMenu: () => void;
  videoCid?: string;
  showCid?: string;
  hideParent: () => void;
}

// element to use to mount the mobile menu
const mobileMenuContainer = document.getElementById('continue-watching-mobile-menu');

const Menu = (props: MenuProps) => {
  const { show, watchUrl, menuText, removeText, layout, closeMenu, videoCid, showCid, hideParent } = props;
  const [removeHasBeenClicked, setRemoveHasBeenClicked] = useState(false);

  const handleRemoveClick = () => {
    // these calls to Profile Services will take a bit to reflect on the frontend
    // (usually one or two hard refreshes, IMO)
    if (videoCid) {
      Profile.remove("watchedVideo", { id: videoCid });
    } else if (showCid) {
      Profile.remove("favoriteShow", { id: showCid });
    }

    // ...so in the meantime, we artificially hide the selected poster from the row via
    // a callback function, and un-render this menu component
    hideParent();
    setRemoveHasBeenClicked(true);
  }

  const menuContent = (
    <div className="poster-with-menu__menu" data-testid="rtl-continueWatchingMenu">
      {layout === 'mobile' &&  (
        <div className="poster-with-menu__menu-header">
          <p className="poster-with-menu__menu-title">{show.title}</p>
          <button className="poster-with-menu__menu-close-btn"
            onClick={closeMenu}
          >
            <CloseIcon />
          </button>
        </div>
      )}
      <ul className="poster-with-menu__list">
        <li className="poster-with-menu__list-item">
          <a href={watchUrl} className="an-61_6">
            <PlayIcon />
            <span>{menuText}</span>
          </a>
        </li>
        <li className="poster-with-menu__list-item">
          <ShowRowPreviewContainer
            title={show.title}
            showSlug={show.slug}
            forceDisplay={true}
          />
        </li>
        <li className="poster-with-menu__list-item">
          <a href={`/show/${show.slug}/`} className="an-61_7">
            <PagesIcon />
            <span>Explore Show</span>
          </a>
        </li>
        <li className="poster-with-menu__list-item">
          <button className="poster-with-menu__remove-btn"
            onClick={handleRemoveClick}
          >
            <CloseIcon />
            <span>{removeText}</span>
          </button>
        </li>
      </ul>
    </div>
  );

  // if the user clicks the "Remove" button, this menu should vanish
  if (removeHasBeenClicked) {
    return null;
  }

  // mount the component inline if window width > 768,
  // mount it outside the parent for mobile layout
  if (layout === 'mobile') {
    return ReactDOM.createPortal(
      menuContent,
      mobileMenuContainer
    );
  } else {
    return menuContent;
  }
}

export default Menu;