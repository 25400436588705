import { getStation } from 'scripts/utils/getStation';
import { getUserId } from 'scripts/utils/getUserId';

const contentServicesGraphql = 'https://content.services.pbs.org/graphql/';

export interface ShowsVariables {
  first: number;
  ordering: string;
  title?: string;
  genre?: string;
  source?: string;
  after?: number;
}

export interface fetchGraphQlVariables {
  slug?: string;
}

async function fetchGraphQL(text: string, variables?: fetchGraphQlVariables) {
  const headers: HeadersInit = {
    "Content-Type": "application/json",
    "X-PBS-Platform": window.stationVideoPortalId ? "svp" : "pbsorg",
    "X-PBS-Platform-Version": "1.0",
  };

  // Get local information
  const userId = getUserId();
  const stationId = getStation().stationId;
  // we should check for user id first;
  // if it does not exist, we should check for station id.
  // cs graphql will accept only one of these at a time.
  if (userId) {
    headers["X-PBS-User-Id"] = userId;
  } else if (stationId) {
    headers["X-PBS-Station-Id"] = stationId;
  }

  const response = await fetch(contentServicesGraphql, {
    method: "POST",
    headers,
    body: JSON.stringify({
      query: text,
      variables,
    }),
  });

  // Get the response as JSON
  return await response.json();
}

export default fetchGraphQL;
