import { WindowPBS } from 'scripts/modules/window.PBS';

declare let window: WindowPBS;

interface stationDetail extends Event {
  detail: {
    station: {
      logo_url: string;
      common_name: string;
    };
  };
}

const handleStationChange = (e: stationDetail) => {
  if (
    window.PBS &&
    window.PBS.Profile &&
    document.cookie.indexOf('pbs_uid') > -1
  ) {
    window.PBS.Profile.favoriteStations.add(e.detail.station);
  }

  e.preventDefault();
  window.location.reload();
};

const handleAutoLocalization = (e: stationDetail) => {
  if (e.detail && e.detail.station) {
    const chooseStation_div = document.querySelector('#pbs_loc_name');
    chooseStation_div.innerHTML = `<img class="station-logo" src="${e.detail.station.logo_url}?resize=80x39&format=png" alt="${e.detail.station.common_name}"/>`;
  }
};

const addEvents = () => {
  // confirm station button on the localization has been pressed
  document.addEventListener('confirmStationEvent', function(e: stationDetail) {
    handleStationChange(e);
  });
  document.addEventListener('jaws.localized', function(e: stationDetail) {
    handleAutoLocalization(e);
  });
};

const init = (): void => {
  addEvents();
};

export { init };
