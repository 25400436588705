import React, { useState, useEffect } from 'react';

import { ShowRowPreviewPlayer } from './preview-player';
import { ReactComponent as PlayIcon } from 'svg/pbs-play--no-circle.svg';

export const ShowRowPreviewHero: React.FC<ShowRowPreviewHeroProps> = (
  props
) => {
  const {
    featuredPreviewData,
    featuredAssetsData,
    showData,
    modalBodyRef,
    previewButtonClassName,
  } = props;
  const [imagePath, setImagePath] = useState('');
  const [hasPreviewVideo, setHasPreviewVideo] = useState(false);
  const [shouldDisplayPlayer, setShouldDisplayPlayer] = useState(false);
  // for uniformity, transform this to all lowercase;
  // we then use CSS to title-case it
  const featuredPreviewVideoType =
    featuredPreviewData?.videoType?.toLowerCase() || 'preview';
  const avifEnabled = window.PBS_AVIF_ENABLED;

  const handleImageClick = () => {
    // we only care about clicks to the image if we have a preview video to play
    if (hasPreviewVideo) {
      // this class lets the show title element know to push down
      modalBodyRef?.current?.classList.add('player-initialized');
      // now tell the component to display the player instead of the image
      setShouldDisplayPlayer(true);
    }
  };

  useEffect(() => {
    // on initial load, determine the image path to use
    if (
      featuredPreviewData &&
      featuredPreviewData.slug &&
      featuredPreviewData.availability === 'available' &&
      featuredPreviewData.mezzanine16x9ImageUrl
    ) {
      // use the featured preview mezzanine image
      setImagePath(featuredPreviewData.mezzanine16x9ImageUrl);
      // also indicate that there is a preview video to play
      setHasPreviewVideo(true);
    } else if (showData && showData.backgroundImageUrl) {
      // otherwise, use the show's curated background image
      setImagePath(showData.backgroundImageUrl);
    } else if (
      featuredAssetsData &&
      featuredAssetsData.length > 0 &&
      featuredAssetsData[0].mezzanine16x9ImageUrl
    ) {
      // as a last resort, we use the first featured asset's mezzanine image
      setImagePath(featuredAssetsData[0].mezzanine16x9ImageUrl);
    } else {
      // if there is TRULY no hero image to display, we add a class to the modal body
      // to make sure we don't adjust the positioning of the rest of the content
      modalBodyRef?.current?.classList.add('no-hero-image');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!imagePath) {
    // return an empty div instead of returning null when there's no image to display
    // this prevents unintentional layout issues - see RWEB-7991
    return <div className="show-row-preview-modal__hero"></div>;
  } else if (shouldDisplayPlayer) {
    return (
      <ShowRowPreviewPlayer
        title={featuredPreviewData.title}
        slug={featuredPreviewData.slug}
      />
    );
  } else {
    return (
      <div
        className="show-row-preview-modal__hero"
        onClick={handleImageClick}
        data-testid="rtl-previewModalHero"
      >
        <picture className="show-row-preview-modal__player-picture">
          {avifEnabled && (
            <source
              type="image/avif"
              srcSet={`${imagePath}?crop=218x122&format=avif 218w,
              ${imagePath}?crop=384x215&format=avif 375w,
              ${imagePath}?crop=480x270&format=avif 480w,
              ${imagePath}?crop=767x432&format=avif 768w,
              ${imagePath}?crop=960x540&format=avif 1024w,
              ${imagePath}?crop=1200x675&format=avif 1280w`}
            />
          )}
          <source
            type="image/webp"
            srcSet={`${imagePath}?crop=218x122&format=webp 218w,
              ${imagePath}?crop=384x215&format=webp 375w,
              ${imagePath}?crop=480x270&format=webp 480w,
              ${imagePath}?crop=767x432&format=webp 768w,
              ${imagePath}?crop=960x540&format=webp 1024w,
              ${imagePath}?crop=1200x675&format=webp 1280w`}
          />
          <img
            srcSet={`${imagePath}?crop=218x122&format=webp 218w,
              ${imagePath}?crop=384x215&format=webp 375w,
              ${imagePath}?crop=480x270&format=webp 480w,
              ${imagePath}?crop=767x432&format=webp 768w,
              ${imagePath}?crop=960x540&format=webp 1024w,
              ${imagePath}?crop=1200x675&format=webp 1280w`}
            src={imagePath}
            className="show-row-preview-modal__player-image image--needs-fallback img-responsive"
            loading="lazy"
            alt={`Play preview for ${showData.title}`}
          />
        </picture>
        {hasPreviewVideo && (
          <button className={previewButtonClassName}>
            <div className="show-row-preview-modal__hero__play-icon">
              <PlayIcon />
            </div>
            <p className="show-row-preview-modal__hero__play-cta">
              Watch{' '}
              <span className="show-row-preview-modal__hero__video-type">
                {featuredPreviewVideoType}
              </span>
            </p>
          </button>
        )}
      </div>
    );
  }
};
