import { getUserId } from 'scripts/utils/getUserId';
import fetchGraphQL from 'scripts/utils/fetchGraphQL';
import { customEvents } from 'scripts/custom-events';

export interface ViewingHistoryItem {
  slug: string;
  percentageWatched: number;
}

export const VIEWING_HISTORY_QUERY = `
  query {
    viewerContext {
      viewingHistory {
        slug
        percentageWatched
      }
    }
  }
`;

const userId: string = getUserId();

const formatAndFilterViewingHistory = (viewingHistory: Array<ViewingHistoryItem>) => {
  const formattedViewingHistory =
    viewingHistory.map((viewingHistoryItem: ViewingHistoryItem) => {
      // we only need "50", not "50.123456789"
      const percentageWatched = Math.round(viewingHistoryItem.percentageWatched);
      return {
        ...viewingHistoryItem,
        percentageWatched: percentageWatched,
      }
    });
  // filter out videos with 0% watched - sometimes this happens
  const fitleredViewingHistory =
    formattedViewingHistory.filter((viewingHistoryItem: ViewingHistoryItem) => {
      return viewingHistoryItem.percentageWatched !== 0
    });
  return fitleredViewingHistory;
}

const getUserViewingHistory = () => {
  // @TODO set some kind of time check / cache life, rather than writing this over every time
  fetchGraphQL(VIEWING_HISTORY_QUERY)
    .then((result) => {
      const viewingHistory = result.data.viewerContext.viewingHistory;
      // send to localStorage
      localStorage.setItem(
        'userViewingHistory',
        JSON.stringify(formatAndFilterViewingHistory(viewingHistory))
      );
      const userViewingHistoryLoaded = new CustomEvent(customEvents.userViewingHistoryLoaded);
      window.dispatchEvent(userViewingHistoryLoaded);
    })
    // eslint-disable-next-line
    .catch(() => console.log('error getting viewing history'));
};

const init = () => {
  // using getUserId as a way to tell that the user is logged in
  if (userId) {
    getUserViewingHistory();
  }
};

export { init, formatAndFilterViewingHistory }
