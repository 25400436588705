import React, { useState, useEffect, useCallback } from 'react';
import { createRoot } from 'react-dom/client';

import { customEvents } from 'scripts/custom-events';
import { savedSecondaryTimezoneMatches } from 'scripts/utils/savedSecondaryTimezoneMatches';
import { storageAvailable, getItem } from 'scripts/utils/storage';

const timezoneToggleEvent = new CustomEvent(customEvents.timezoneToggle);

type TimezoneToggleProps = {
  secondaryTimezone: string;
  displayPrimaryTimezone: string;
  displaySecondaryTimezone: string;
  setSecondaryTimezoneSelectedInStorage: (arg0:string) => void;
  removeSecondaryTimezoneSelectedInStorage: () => void;
}

const setSecondaryTimezoneSelectedInStorage = (timezone: string) => {
  localStorage.setItem(
    'secondaryTimezoneSelected',
    timezone
  );
  window.dispatchEvent(timezoneToggleEvent);
}

const removeSecondaryTimezoneSelectedInStorage = () => {
  localStorage.removeItem('secondaryTimezoneSelected');
  window.dispatchEvent(timezoneToggleEvent);
}

const TimezoneToggle: React.FC<TimezoneToggleProps> = (props) => {
  /**
   * Creates class
   * @param {object} props - default props
   */
  const [secondaryTimezoneSelected, setSecondaryTimezoneSelected] = useState(
    false
  );
  const { displayPrimaryTimezone, displaySecondaryTimezone, setSecondaryTimezoneSelectedInStorage, removeSecondaryTimezoneSelectedInStorage } = props;

  /* ------------------------------------------------------------ */
  // called on initialization (event listener is added)
  // recall the localStorage value if set
  const handleOtherTimeToggle = useCallback(() => {
    if (savedSecondaryTimezoneMatches(props.secondaryTimezone)) {
      setSecondaryTimezoneSelected(true);
    } else {
      setSecondaryTimezoneSelected(false);
    }
  }, [props.secondaryTimezone]);

  const handlePrimaryTimezoneClick = () => {
    if (storageAvailable('localStorage')) {
      removeSecondaryTimezoneSelectedInStorage();
      setSecondaryTimezoneSelected(false);
    }
  };

  const handleSecondaryTimezoneClick = () => {
    if (storageAvailable('localStorage')) {
      setSecondaryTimezoneSelectedInStorage(props.secondaryTimezone)
      setSecondaryTimezoneSelected(true);
    }
  };

  useEffect(() => {
    window.addEventListener(customEvents.timezoneToggle, handleOtherTimeToggle);
    if (getItem('secondaryTimezoneSelected')) {
      setSecondaryTimezoneSelected(true);
    }
    return () => {
      window.removeEventListener(customEvents.timezoneToggle, handleOtherTimeToggle);
    };
  }, [handleOtherTimeToggle]);
  /* ------------------------------------------------------------ */

  return (
    <React.Fragment>
      <span className="intro">Timezone:</span>
      <span className="toggle_bg">
        <button
          onClick={() => handlePrimaryTimezoneClick()}
          className={`timezone-toggle__btn`}
          type={`button`}
          disabled={secondaryTimezoneSelected ? false : true}
          aria-pressed={secondaryTimezoneSelected ? false : true}
          aria-label={`Change TV schedules timezone to ${displayPrimaryTimezone}`}
        >
          <span className="primary">{displayPrimaryTimezone}</span>
        </button>
        {storageAvailable('localStorage') ? (
          <React.Fragment>
            <button
              onClick={() => handleSecondaryTimezoneClick()}
              className={`timezone-toggle__btn`}
              type={`button`}
              disabled={secondaryTimezoneSelected ? true : false}
              aria-pressed={secondaryTimezoneSelected ? true : false}
              aria-label={`Change TV schedules timezone to ${displaySecondaryTimezone}`}
            >
              <span className="secondary">{displaySecondaryTimezone}</span>
            </button>
          </React.Fragment>
        ) : (
          ''
        )}
      </span>
    </React.Fragment>
  );
};

const renderToggle = (toggle: HTMLElement) => {
  const secondaryTimezone = toggle.dataset.secondaryTimezone;
  const displayPrimaryTimezone = toggle.dataset.displayTimezone;
  const displaySecondaryTimezone = toggle.dataset.displaySecondaryTimezone;

  const root = createRoot(toggle!);
  root.render(
    <TimezoneToggle
      secondaryTimezone={secondaryTimezone}
      displayPrimaryTimezone={displayPrimaryTimezone}
      displaySecondaryTimezone={displaySecondaryTimezone}
      setSecondaryTimezoneSelectedInStorage={setSecondaryTimezoneSelectedInStorage}
      removeSecondaryTimezoneSelectedInStorage={removeSecondaryTimezoneSelectedInStorage}
    />
  );
};

/**
 * Initializes component.
 * @returns {function} _init
 */
const init = (toggle: HTMLElement): void => {
  // we need to check that there is, in fact, a toggle
  if (toggle) {
    renderToggle(toggle);
  }
};

export { init, TimezoneToggle, savedSecondaryTimezoneMatches, setSecondaryTimezoneSelectedInStorage, removeSecondaryTimezoneSelectedInStorage };
