import ResponsiveAd from './ad-responsive';
import AdhesionAd from './ad-adhesion';
import LazyloadAd from './ad-lazyload';
import { customEvents } from 'scripts/custom-events';
import { isTouchDevice } from 'scripts/utils/isTouchDevice';

interface AdsData {
  ad_slots?: Array<Slot>;
  adhesion_slot?: Slot;
}

/**
 * Data from python bundle, set on _init.
 * @type {object} _data
 */
let data: AdsData = {};

/**
 * Instantiates new ads based on data passed from view.
 */
const createAds = (ads: Array<Slot>, adClass: typeof AdhesionAd | typeof ResponsiveAd) => {
  ads.map((adData) => {
    let AdClass = adClass;

    if (adData.lazyload) {
      AdClass = LazyloadAd;
    }

    const ad = new AdClass(adData);
    ad.render();
  });
};

/**
 * Waits for DOM and user data before creating ads.
 * As of RWEB-8009, we need not just DOM ready, but user data
 * before we instantiate ads. This is because we need to know if
 * the user is Passport, non-Passport, or signed out--this user
 * status is reported as a key-value pair in ad.ts
 */
const onPageReady = (): void => {
  if (data.ad_slots && !isTouchDevice()) {
    createAds(data.ad_slots, ResponsiveAd);
  }

  if (data.adhesion_slot) {
    createAds([data.adhesion_slot], AdhesionAd);
  }

  document.removeEventListener(customEvents.userStatusIsKnown, onPageReady);
};

/**
 * Enables pubads service once,
 * implementing async rendering and disabling initial load
 */
const enablePubServices = (): void => {
  window.googletag.cmd.push(function () {
    window.googletag.pubads().enableAsyncRendering();

    // disable initial load
    // google's recommended implementation of lazy loading
    // see https://support.google.com/dfp_premium/answer/4578089?hl=en
    window.googletag.pubads().disableInitialLoad();
    window.googletag.enableServices();
  });
};

/**
 * Inits ads component.
 * @param {ojbect} data - ad data from python
 */
const init = (): void => {
  // googletag exists
  if (window.googletag) {
    data = window.PBS.Ads;

    enablePubServices();

    // add event listener for when DOM & user data is ready
    // need to wait to add events, cache els, etc
    window.addEventListener(customEvents.userStatusIsKnown, onPageReady);
  }
};

export { init };
