import Cookies from 'js-cookie';
import { WindowPBS } from 'scripts/modules/window.PBS';
import { getStationShortCommonName } from 'scripts/utils/getStationShortCommonName';

declare let window: WindowPBS;

interface Station {
  stationId: string;
  stationName: string;
}

/**
 * Checks window object and cookies for station data.
 */
const getStation = (): Station => {
  const stationId = window.stationVideoPortalId
    ? window.stationVideoPortalId
    : Cookies.get('pbsol.station_id');
  const station = {
    stationId: stationId,
    stationName: getStationShortCommonName(),
  };
  return station;
}

export { getStation }
