import React, { ReactPortal } from 'react';
import ReactDOM from 'react-dom';

import { VideoPlaybackModal } from 'components/video-playback-modal/video-playback-modal';
import { buildPlayerUrl } from 'scripts/modules/pbs-player-iframe';
import { RelatedAssetModalProps } from './types';

// element to attach to the DOM
const containerElement = document.createElement('div')

export const RelatedAssetModalComponent: React.FC<RelatedAssetModalProps> = (props) => {
  const { handleClose, videoData } = props;

  // using the right variation of the player based on whether we're in the SVP or not
  const playerEmbedType = document
    .querySelector(`body`)
    .classList.contains(`svp`)
    ? `stationplayer/`
    : `portalplayer/`;

  // if there is a window object with an embedURL, use that, else, fallback to player prod
  const playerEmbedURL =
    window.PBS?.playerConfig?.embedURL ?
      window.PBS.playerConfig.embedURL :
      `https://player.pbs.org/`;

  const playerSource = buildPlayerUrl({
    embedType: playerEmbedType,
    embedURL: playerEmbedURL,
    id: videoData.related_video_legacy_tp_media_id,
    slug: videoData.slug,
    disableContinuousPlay: true,
  });
  const playerId = `player-${videoData.slug}`;

  return (
    <VideoPlaybackModal
      handleClose={handleClose}
      customClass="related-asset-modal"
      customName="related"
      containerElement={containerElement}
      testId="rtl-relatedAssetModal"
    >
      <div className="related-asset-modal__topbar modal-window__topbar">
        <h2 className="related-asset-modal__title">
          {videoData.related_video_title}
        </h2>
      </div>
      <div className="related-asset-modal__player-container" id={playerId}>
        <iframe
          title={videoData.related_video_title}
          name="related"
          src={playerSource}
          allowFullScreen
          allow="encrypted-media"
        />
      </div>
    </VideoPlaybackModal>
  );
}

/*
* Using React Portal here to cause the modal to mount higher in the DOM tree
* Otherwise, it would mount underneath the Tippy-based popovers and be constrained
* to the popover box instead of the full screen as intended.
*/
export const RelatedAssetModal: React.FC<RelatedAssetModalProps> = (props): ReactPortal => {
  return ReactDOM.createPortal(
    <RelatedAssetModalComponent {...props} />,
    containerElement
  );
}
