import { isViewportOver } from 'scripts/utils/isViewportOver';
import { isTouchDevice } from 'scripts/utils/isTouchDevice';
import { hideStationMenu } from './hideStationMenu';
import { isTabKeyPress } from 'scripts/utils/isTabKeyPress';
interface CacheExpectations {
  body?: HTMLBodyElement;
  searchNavTrigger?: HTMLButtonElement;
  mainNavTrigger?: HTMLButtonElement;
  searchInput?: HTMLInputElement;
  donateLink?: HTMLLinkElement;
  livestreamLink?: HTMLLinkElement;
  linksToSkip?: NodeListOf<HTMLLinkElement>;
}

const cache:CacheExpectations = {};

/**
 * Caches re-used elements.
 */
const setupCache = (): void => {
  cache.body = document.querySelector('body');
  cache.searchNavTrigger = document.querySelector('.page-header__buttons__search-nav-trigger');
  cache.mainNavTrigger = document.querySelector('.page-header__buttons__main-nav-trigger');
  cache.searchInput = document.querySelector('#header-search');
  cache.donateLink = document.querySelector('#donate-nav-link, #donateUnlocalized');
  cache.livestreamLink = document.querySelector('.nav-item__link--livestream');
  cache.linksToSkip = document.querySelectorAll(
    '.page-header__brand, .page-header__station-trigger, .choose-station'
  );
};

/**
 * Helper function that toggles index on links we may or may not want to skip
 * @param {string} index - the index that we want the tab index to be. note it's set as a string
 */
const setLinksTabIndex = (index:string): void => {
  cache.linksToSkip.forEach(link => {
    link.setAttribute('tabindex', index)
  });
}

/**
 * Shows the main nav.
 */
const showMainNav = (): void => {
  hideStationMenu();
  hideSearchNav();

  cache.body.classList.add('nav-is-open');
  cache.body.classList.add('main-nav-is-open');

  cache.mainNavTrigger.querySelector('.active-icon').classList.add('is-hidden');
  cache.mainNavTrigger.querySelector('.close-icon').classList.remove('is-hidden');

  cache.mainNavTrigger.setAttribute('aria-label', 'Close Main Menu');
  setLinksTabIndex('-1');
};

/**
 * Shows the search nav.
 */
const showSearchNav = (): void => {
  hideStationMenu();
  hideMainNav();

  cache.body.classList.add('nav-is-open');
  cache.body.classList.add('search-nav-is-open');

  cache.searchNavTrigger.querySelector('.active-icon').classList.add('is-hidden');
  cache.searchNavTrigger.querySelector('.close-icon').classList.remove('is-hidden');

  cache.searchNavTrigger.setAttribute('aria-label', 'Close Search Menu');
  setLinksTabIndex('-1');
};

/**
 * Hides the main side nav.
 */
const hideMainNav = (): void => {
  cache.body.classList.remove('nav-is-open');
  cache.body.classList.remove('main-nav-is-open');

  cache.mainNavTrigger.querySelector('.active-icon').classList.remove('is-hidden');
  cache.mainNavTrigger.querySelector('.close-icon').classList.add('is-hidden');

  // cache.mainNavTrigger.blur().setAttribute('aria-label', 'Open Main Menu');
  cache.mainNavTrigger.addEventListener('blur', () => {
    cache.mainNavTrigger.setAttribute('aria-label', 'Open Main Menu');
  })

  setLinksTabIndex('0');

  // Remove focus from search input on touch devices - fixes problem of disappearing header
  if (isTouchDevice()) {
    cache.searchInput.blur();
  }
};

/**
 * Hides the left side nav.
 */
const hideSearchNav = (): void => {
  cache.body.classList.remove('nav-is-open');
  cache.body.classList.remove('search-nav-is-open');

  cache.searchNavTrigger.querySelector('.active-icon').classList.remove('is-hidden');
  cache.searchNavTrigger.querySelector('.close-icon').classList.add('is-hidden');

  // cache.searchNavTrigger.blur().setAttribute('aria-label', 'Open Search Menu');
  cache.searchNavTrigger.addEventListener('blur', () => {
    cache.searchNavTrigger.setAttribute('aria-label', 'Open Search Menu');
  })

  setLinksTabIndex('0');

  // Remove focus from search input on touch devices - fixes problem of disappearing header
  if (isTouchDevice()) {
    cache.searchInput.blur();
  }
};

/**
 * Hides both navs.
 */
const hideNav = (): void => {
  hideMainNav();
  hideSearchNav();
};

/**
 * Handles a click to the search nav trigger on mobile.
 * @param {Event} e
 */
const onSearchNavTriggerClick = (e: Event): void => {
  e.preventDefault();
  if (cache.body.classList.contains('search-nav-is-open')) {
    hideSearchNav();
  } else {
    showSearchNav();
  }
};

/**
 * Handles a click to the main nav trigger on mobile.
 * @param {Event} e
 */
const onMainNavTriggerClick = (e: Event): void => {
  e.preventDefault();
  if (cache.body.classList.contains('main-nav-is-open')) {
    hideMainNav();
  } else {
    showMainNav();
  }
};

/**
 * Handles tabbing through on the last link - sends user back to close main nav
 * @param {KeyboardEvent} e
 */
const onLastLinkKeyDown = (e: globalThis.KeyboardEvent): void => {
  if (isTabKeyPress(e) && !isViewportOver(1024) && cache.body.classList.contains('nav-is-open')) {
    // send the user back to the mainNavTrigger if
    // the nav is open and we're below 1024
    e.preventDefault();
    cache.mainNavTrigger.focus();
    return;
  }
};

/**
 * Adds event handlers.
 */
const addEvents = (): void => {
  // Open/close search menu on click
  cache.searchNavTrigger.addEventListener('click', onSearchNavTriggerClick);
  // Open/close user menu on click
  cache.mainNavTrigger.addEventListener('click', onMainNavTriggerClick);
  // When user tabs on the last link - either the livestream or donate link
  const lastLink:HTMLElement = cache.livestreamLink ? cache.livestreamLink : cache.donateLink;
  lastLink.addEventListener('keydown', onLastLinkKeyDown);
};

/**
 * On init.
 */
const init = (): void => {
  setupCache();
  addEvents();
};

export { init, hideNav };
