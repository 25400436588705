import { storageAvailable, getItem } from './storage';

/**
 * Checks passed timezone against what is in localStorage
 * @type {string} passedTimezone
 * @returns {boolean}
 */
const savedSecondaryTimezoneMatches = (
  passedTimezone: string,
  depSavedSecondaryTimezone?: string
): boolean => {
  let savedSecondaryTimezone: string = null;

  if (depSavedSecondaryTimezone) {
    savedSecondaryTimezone = depSavedSecondaryTimezone;
  } else if (storageAvailable(`localStorage`)) {
    savedSecondaryTimezone = getItem(`secondaryTimezoneSelected`);
  }

  if (savedSecondaryTimezone && savedSecondaryTimezone == passedTimezone) {
    return true;
  } else {
    return false;
  }
};

export { savedSecondaryTimezoneMatches };
