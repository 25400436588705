import Cookies from 'js-cookie';

const expireCookieDays = 30;

// provided by the DOM itself
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const GTMDataLayer: any;

/**
 * Registering the modal impression via the GTM Data Layer
 */
const addShowEventToGTM = () => {
  GTMDataLayer.push({
    event: 'PassportModalImpression',
  });
};

/**
 * Registering the modal closing via the GTM Data Layer
 */
const addCloseEventToGTM = () => {
  GTMDataLayer.push({
    event: 'PassportModalClosed',
  });
};

/**
 * Creates cookies so users is only shown the modal every so often
 */
const createHideModalCookie = () => {
  Cookies.set('pbs.hidePassportModal', 'true', {
    expires: expireCookieDays,
    secure: true,
    sameSite: 'Lax',
  });
};

/**
 * From modal.js, called when modal is shown
 */
const onShow = (): void => {
  if (GTMDataLayer) {
    addShowEventToGTM();
  }
  createHideModalCookie();
};

/**
 * From modal.js, called when modal is closed
 */
const onHide = (): void => {
  if (GTMDataLayer) {
    addCloseEventToGTM();
  }
};

export { onShow, onHide };
