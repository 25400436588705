
/**
 * Config for default personal values.
 * @type {object} config
 */
const config = {
  personal: {
    url: '/personal/',
    contentType: 'application/json',
    charset: 'utf-8',
    dataType: 'json',
    type: 'post',
  },
  logout: {
    url: '/logout/',
    type: 'post',
  },
};

/**
 * Gets payload data to send with /personal request
 * @returns {JSON string} - of data to send if applicable
 */
const getPayloadData = () => {
  return JSON.stringify({
    videoSlug:
      window.PBS && window.PBS.playerConfig
        ? window.PBS.playerConfig.slug
        : null,
  });
};

/**
 * Sends /personal request.
 * @returns {Promise}
 */
const sendRequest = (): Promise<Response> => {
  return fetch(config.personal.url, {
    method: config.personal.type,
    headers: {
      'Content-Type': config.personal.contentType,
      'Accept-Charset': config.personal.charset,
    },
    body: getPayloadData(),
  })
  .then((response) => {
    if (response.ok) {
      return response;
    } else {
      throw Error(response.status.toString());
    }
  })
};

/**
 * Logs user out.
 * @returns {Promise}
 */
const logout = (): Promise<Response> => {
  return fetch(config.logout.url, { method: config.logout.type });
};

export { sendRequest, logout };
