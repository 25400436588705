import { getUserId } from 'scripts/utils/getUserId';
import { customEvents } from 'scripts/custom-events';
import { getVideoPercentageWatched } from 'scripts/utils/getVideoPercentageWatched';

const userId: string = getUserId();

interface CacheExpectations {
  videoSummaries?: NodeListOf<HTMLDivElement>
}

const cache: CacheExpectations = {}

const setupCache = () => {
  cache.videoSummaries = document.querySelectorAll('.video-summary');
}

const appendProgress = (videoSummary: HTMLDivElement, percentageWatched: number | null) => {
  const progressBar = document.createElement('progress');
  progressBar.max = 100;
  progressBar.value = percentageWatched;
  progressBar.classList.add('viewing-history-progress');
  videoSummary.querySelector('.video-summary__link').append(progressBar);
};

const matchViewingHistoryToVideoSummaries = () => {
  cache.videoSummaries.forEach((videoSummary) => {
    const slug = videoSummary.dataset.videoSlug;
    const percentageWatched = getVideoPercentageWatched(slug);
    if (percentageWatched) {
      appendProgress(videoSummary, percentageWatched);
    }
  });
};

export const init = () => {
  // using getUserId as a way to tell that the user is logged in
  if (userId) {
    setupCache();
    window.addEventListener(customEvents.userViewingHistoryLoaded, matchViewingHistoryToVideoSummaries);
  }
};
