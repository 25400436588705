import { customEvents } from 'scripts/custom-events';

interface CacheExpectations {
  nonMemberContent?: HTMLElement;
  memberContent?: HTMLElement;
}

const cache: CacheExpectations = {};

/**
 * Caches re-used elements.
 */
const setupCache = () => {
  cache.nonMemberContent = document.querySelector('.station-passport-promo__non-member');
  cache.memberContent = document.querySelector('.station-passport-promo__member');
};

const onUserIsPassport = () => {
  cache.nonMemberContent.classList.add('is-hidden');
  cache.memberContent.classList.remove('is-hidden');

  window.removeEventListener(customEvents.userIsPassport, onUserIsPassport);
};

/**
 * Adds event handlers.
 */
const addEvents = () => {
  // this custom event is emitted when the user data is loaded
  window.addEventListener(customEvents.userIsPassport, onUserIsPassport);
};

/**
 * Initializes.
 */
const init = (): void => {
  setupCache();
  addEvents();
};

export { init };
