import React, { ReactPortal } from 'react';
import ReactDOM from 'react-dom';

import { VideoPlaybackModal } from 'components/video-playback-modal/video-playback-modal';
import { ShowRowPreviewHero } from './hero';
import { ShowRowPreviewDetails } from './details';
import { ShowRowPreviewFeaturedAssets } from './featured-assets';
import { toggleVideoPlayback } from 'scripts/modules/pbs-player-iframe';

// element to attach to the DOM
const containerElement = document.createElement('div');

export const ShowRowPreviewModalComponent: React.FC<ShowRowPreviewModalProps> = (props) => {
  const { showData, featuredPreviewData, featuredAssetsData, handleClose, modalBodyRef } = props;

  // consts so that we have class name agreement where it matters
  const previewButtonClassName = 'show-row-preview-modal__hero__play-overlay';
  const customName = 'show-row-preview';

  const showRowPreviewModalSpacebarHandler = (e: React.KeyboardEvent<HTMLElement>) => {
    // prevent page scrolling
    e.preventDefault();
    const button: HTMLButtonElement = document.querySelector(`.${previewButtonClassName}`);
    // if the button exists, click it; else toggle playback
    button ? button.click() : toggleVideoPlayback(e, customName);
  }

  return (
    <VideoPlaybackModal
      handleClose={handleClose}
      customClass="show-row-preview-modal"
      customName={customName}
      customSpacebarHandler={showRowPreviewModalSpacebarHandler}
      containerElement={containerElement}
      testId="rtl-previewModal"
    >
      <ShowRowPreviewHero
        featuredPreviewData={featuredPreviewData}
        featuredAssetsData={featuredAssetsData}
        showData={showData}
        modalBodyRef={modalBodyRef}
        previewButtonClassName={previewButtonClassName}
      />
      <ShowRowPreviewDetails
        cid={showData.cid}
        descriptionLong={showData.descriptionLong}
        whiteLogo4x1ImageUrl={showData.whiteLogo4x1ImageUrl}
        slug={showData.slug}
        title={showData.title}
      />
      {featuredAssetsData?.length > 0 && (
        <ShowRowPreviewFeaturedAssets
          slug={showData.slug}
          title={showData.title}
          featuredAssetsData={featuredAssetsData}
        />
      )}
    </VideoPlaybackModal>
  );
}

/*
* Using React Portal here to cause the modal to mount higher in the DOM tree
* Otherwise, it would mount underneath the Tippy-based popovers and be constrained
* to the popover box instead of the full screen as intended.
*/
export const ShowRowPreviewModal: React.FC<ShowRowPreviewModalProps> = (props): ReactPortal => {
  return ReactDOM.createPortal(
    <ShowRowPreviewModalComponent {...props} />,
    containerElement
  );
}
