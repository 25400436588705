import { MaxedOutModalData } from '../types/my-list-button';
import { editProfileData } from './editProfileData';

const proceedToAddItem = (data: MaxedOutModalData): void => {
  const { type, cid, slug, callback } = data;
  editProfileData('add', cid, type, slug).then(() => {
    callback(true);
  });
}

export { proceedToAddItem }
