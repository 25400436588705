import Modal from 'components/modal/modal';
import * as MaxedOutModal from '../../maxed-out/maxed-out-modal';
import * as SignInModal from 'components/sign-in/sign-in';
import { PersonalData, FavoriteShow, WatchlistVideo, processedPersonalData } from '../types/my-list-button';


// Helper function to take in the window.PBS.personalData object
// and format it for ease of use with the My List button
export const processPersonalData = (personalData: PersonalData): processedPersonalData => {
  // instantiate default values
  let isSignedIn = false;
  let showsFull = false;
  let videosFull = false;
  let showsList: FavoriteShow[] = [];
  let videosList: WatchlistVideo[] = [];

  // update these values if the user is signed in;
  // (personalData will be empty object or null if user is not signed in)
  if (personalData && Object.keys(personalData).length !== 0) {
    isSignedIn = true;
    showsFull = personalData.shows_full;
    videosFull = personalData.videos_full;
    showsList = personalData.favorite_shows && personalData.favorite_shows.content;
    videosList = personalData.favorite_videos && personalData.favorite_videos.content;
  }

  return {
    isSignedIn,
    showsFull,
    videosFull,
    showsList,
    videosList
  };
}

// Helper function to determine if a given slug is present
// in a user's List. Works for both Show and Video slugs.
export const itemIsInList = (slug: string, list: FavoriteShow[] | WatchlistVideo[]): FavoriteShow | WatchlistVideo => {
  const matchedItem = list?.filter(
    (item: { id: string }) => item.id === slug
  )[0];
  return matchedItem;
}

// If the user has 100 items in My List - Shows (aka Favorite Shows) or
// if the user has 100 items in My List - Videos (aka Watchlist)
// we show a modal informing them of this fact and offering them options.
// 1. Remove the first item in their List and proceed to add the new one
// 2. Take them to My List, where they can remove things at will
// 3. Cancel to close the modal and do nothing
export const handleMaxedOutList = (cid: string, contentType: 'video' | 'show', slug: string, callback: (arg0: boolean) => void): void => {
  const options = {
    modalId: '#maxedOutListModalWindow',
    modalTrigger: `button[data-cid="${cid}"]`,
    focusTarget: '#maxed-out__dialog',
    childView: MaxedOutModal,
    lastFocusableEl: '#close-maxed-out'
  };
  const maxedOutModal = new Modal(options);
  maxedOutModal.show({
    type: contentType,
    cid: cid,
    slug: slug,
    callback: callback
  });
}

// If the user isn't logged in, we need to display the sign-in
// modal so that they can proceed to add an item to their List
export const handleLoggedOutUser = (cid: string, contentType: 'video' | 'show'): void => {
  const options = {
    modalId: '#signInModalWindow',
    modalTrigger: document.querySelector(`button[data-cid="${cid}"]`),
    focusTarget: '#sign-in__dialog',
    childView: SignInModal
  };
  const signInModal = new Modal(options);
  const messageType = contentType == `show` ? `favorite-show` : `watchlist`;

  // Invoke sign-in modal
  signInModal.show({
    type: messageType
  });
};
