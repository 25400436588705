import Splide from "@splidejs/splide";

// Handles cases where on load or resize and we don't do not require a next arrow
// Note: `throttle` must be set on the individual splide instance
export const hideShowNextArrow = (splide: Splide, htmlContainer: HTMLElement): void => {
  // for some reason `resize` doesn't appear on https://splidejs.com/events/
  splide.on('resize', () => {
    const slidesPerPage = splide.options.perPage;
    // `.splide__slide` is class used by the splide library itself
    const numSlides = htmlContainer.querySelectorAll(`.splide__slide`).length;
    if (numSlides <= slidesPerPage) {
      splide.Components.Arrows.arrows.next.classList.add('is-hidden');
    } else {
      splide.Components.Arrows.arrows.next.classList.remove('is-hidden');
    }
  });
}
