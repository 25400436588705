import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

import { customEvents } from 'scripts/custom-events';
import { initializeRelatedAsset } from 'components/video/related-asset/related-asset';

const setupVideoSummaryPopover = (video: HTMLElement): void => {
  const parentVideo = {
    ancestor: video.dataset.ancestor,
    title: video.dataset.title,
    cid: video.dataset.cid,
    type: video.dataset.videoType,
    slug: video.dataset.videoSlug,
  };

  const popoverContent = video.querySelector('.popover');
  tippy(video, {
    aria: {
      content: null,
      expanded: false,
    },
    content: popoverContent,
    delay: [100, 100],
    interactive: true,
    maxWidth: 385,
    placement: 'right',
    popperOptions: {
      modifiers: [
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['left'],
          },
        },
      ],
    },
    theme: 'light',
    touch: false,
  });

  popoverContent.classList.remove('is-hidden');

  const swapPopoverContentIfUserIsPassport = () => {
    // RWEB-6787 Passport rebrand: swapping elements when a Passport Memember is logged in
    // These two elements only appear on passport videos, and we will remove them from the DOM
    // if the user is passport
    popoverContent.querySelector('.popover-video__passport-info')?.remove();
    popoverContent.querySelector('.btn--watch-related-placeholder-inline')?.remove();
    // this element only has the `is-hidden` class on passport videos
    popoverContent.querySelector('.popover--video__watch-buttons')?.classList.remove('is-hidden');
    window.removeEventListener(customEvents.userIsPassport, swapPopoverContentIfUserIsPassport);
  }

  window.addEventListener(customEvents.userIsPassport, swapPopoverContentIfUserIsPassport);

  const relatedAssetPlaceholder:HTMLElement = popoverContent?.querySelector(
    '#related-' + parentVideo.slug
  );

  const inlineRelatedAssetPlaceholder:HTMLElement = popoverContent?.querySelector(
    `#related-${parentVideo.slug}-inline`
  );

  if (relatedAssetPlaceholder) {
    initializeRelatedAsset(parentVideo, relatedAssetPlaceholder, false);
  }

  if (inlineRelatedAssetPlaceholder) {
    initializeRelatedAsset(parentVideo, inlineRelatedAssetPlaceholder, true);
  }
};

export { setupVideoSummaryPopover };
