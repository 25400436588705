import { captureException } from '@sentry/browser';

const customLogMsg = (msg: string) => {

  const isSVP = window.stationVideoPortalId !== undefined;
  const isPBSorg = window.location.hostname.includes('.pbs.org');

  if (isSVP || isPBSorg) {
    fetch('/data/instrumentation/', {
      method: 'POST',
      keepalive: true, // need this to avoid interrupting call when user navigates off page
      body: msg,
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.status.toString());
      }
    })
    .catch((err) => {
      captureException(err);
      // eslint-disable-next-line no-console
      console.error(err);
    });
  }
}

export { customLogMsg }
