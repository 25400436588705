import React, { useState, useEffect } from 'react';
import { ShowRowPreviewButton } from './button';
import { ShowRowPreviewModal } from './modal/modal';
import fetchGraphQL from 'scripts/utils/fetchGraphQL';

const SHOW_ROW_PREVIEW_MODAL = `
  query ShowRowPreviewModal($slug: String!) {
    showContext(showSlug: $slug) {
      show {
        slug
        title
        cid
        whiteLogo4x1ImageUrl
        descriptionLong
        backgroundImageUrl
      }
      featuredPreview {
        cid
        slug
        title
        videoType
        mezzanine16x9ImageUrl
        availability
      }
      featuredFullLengthAssets(first: 3) {
        edges {
          node {
            cid
            slug
            title
            duration
            videoType
            descriptionShort
            mezzanine16x9ImageUrl
            isMvod
            ordinalDisplay(format: COMPACT)
          }
        }
      }
    }
  }
`;

export const ShowRowPreviewContainer: React.FC<ShowRowPreviewContainerProps> = (props) => {
  const { title, showSlug, forceDisplay } = props;
  const [shouldDisplayModal, setShouldDisplayModal] = useState(false);
  const [noShowDataReturned, setNoShowDataReturned] = useState(false);
  const [showData, setShowData] = useState({});
  const [featuredPreviewData, setFeaturedPreviewData] = useState({});
  const [featuredAssetsData, setFeaturedAssetsData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    /**
     * Makes a query to CS Graphql to get the Show Preview data.
     */
    fetchGraphQL(SHOW_ROW_PREVIEW_MODAL, {slug: showSlug})
      .then(response => {
        if (!isMounted) {
          // Avoid updating state if the component unmounted before the fetch completes
          return;
        }
        /**
         * Parses out the data response.
         */
        if (response.data?.showContext?.show) {
          setShowData(response.data.showContext.show);
          setFeaturedPreviewData(response.data.showContext.featuredPreview);

          // pull the three featured assets from their nested data structure
          const featuredAssets: Asset[] = [];
          response.data.showContext.featuredFullLengthAssets.edges.forEach((item: AssetNode) => {
            featuredAssets.push(item.node);
          });
          setFeaturedAssetsData(featuredAssets);
          setLoading(false);
        } else if (response.data) {
          // some posters, e.g. Masterpiece franchise, will return a showContext
          // but it'll be empty, and we should therefore not show the Show Preview button
          setNoShowDataReturned(true);
          setLoading(false);
        }
      })
      .catch(() => {
        setError(true);
        setLoading(false)
      })

    return () => {
      isMounted = false;
    }

  }, [showSlug]);

  const handlePreviewButtonClick = () => {
    setShouldDisplayModal(true);
  }

  const handleModalClose = () => {
    setShouldDisplayModal(false);
  }

  if (!forceDisplay && (loading || error || noShowDataReturned)) return null;

  return (
    <>
      <ShowRowPreviewButton
        title={title}
        handleClick={handlePreviewButtonClick}
      />
      {shouldDisplayModal && (
        <ShowRowPreviewModal
          showData={showData}
          featuredPreviewData={featuredPreviewData}
          featuredAssetsData={featuredAssetsData}
          handleClose={handleModalClose}
        />
      )}
    </>
  );
}
