/**
 * Gets the short common name for use in the front end
 */
const getStationShortCommonName = (): string | null => {
  return window.PBS_STATION_NAME
    ? window.PBS_STATION_NAME
    : `PBS`;
}

export {  getStationShortCommonName }
