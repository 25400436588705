import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';

import ImageWithFallback from 'components/shared/react-image-with-fallback';
import ProgressBar from 'components/shared/progress-bar';
import Menu from './menu';
import { useWindowWidth } from 'components/shared/useWindowWidth';
import { ReactComponent as PlayCircleIcon } from 'svg/pbs-play-circle.svg';
import { ReactComponent as KabobMenu } from 'svg/pbs-kabob-menu.svg';

interface PosterWithMenuProps {
  posterType: 'continue-watching' | 'my-list';
  show: {
    'slug': string;
    'title': string;
    'show-poster2x3': string;
  };
  percentComplete?: number;
  videoSlug?: string;
  videoSummary?: string;
  videoCid?: string;
  showCid?: string;
  hideParent?: () => void;
  userMenuLayout?: boolean;
  posterWithOpenMenu?: string;
  setPosterWithOpenMenu?: Dispatch<SetStateAction<string>>;
  posterGTMAction?: string;
  textLinkGTMAction?: string;
}

const PosterWithMenu: React.FC<PosterWithMenuProps> = (props) => {
  const { posterType, show, percentComplete, videoSlug, videoSummary, videoCid, showCid, hideParent, userMenuLayout, posterWithOpenMenu, setPosterWithOpenMenu, posterGTMAction, textLinkGTMAction } = props;
  const [hasBrokenImage, setHasBrokenImage] = useState(false);
  const [articleClasses, setArticleClasses] = useState('poster-with-menu__item show-poster');
  const [shouldDisplayMenu, setShouldDisplayMenu] = useState(false);

  const width = useWindowWidth();
  const shouldDisplayProgressBar = posterType === "continue-watching" && percentComplete && (percentComplete > 0);

  // define pieces of text for Continue Watching variant
  let badgeClasses = "poster-with-menu__badge badge__continue-watching";
  let badgeText = "Continue Watching";
  let badgeTextMin = "Continue"; // shortened version for smaller widths
  let menuText = badgeText;
  let removeText = "Remove from Row";
  let watchUrl = `/video/${videoSlug}/`;
  let posterClasses = "poster-with-menu__link-container an-61_1";
  let menuClasses = "poster-with-menu__menu-button menu-button__continue-watching";
  let textLinkClasses = "poster-with-menu__menu-button__link an-61_3";

  // use different text for My List variant
  if (posterType === 'my-list') {
    badgeClasses = "poster-with-menu__badge badge__my-list";
    badgeText = "My List";
    badgeTextMin = badgeText; // no change at smaller widths for My List
    menuText = "Start Watching";
    removeText = "Remove from My List";
    watchUrl = `/show/${show.slug}/` // we don't yet get any video information for My List items
    posterClasses = "poster-with-menu__link-container an-61_2";
    menuClasses = "poster-with-menu__menu-button menu-button__my-list";
    textLinkClasses = "poster-with-menu__menu-button__link an-61_4";
  }

  // handle a missing show poster 
  useEffect(() => {
    if (hasBrokenImage) {
      setArticleClasses(`${articleClasses} fallback-image__container fallback-image__container--blue`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasBrokenImage]);

  // handles posters opening menus - must only be one at a time
  // This is handled separately from handleKabobClick so that we
  // can keep track of posterWithOpenMenu (state from parent component)
  // and shouldDisplayMenu (state from individual component) independently
  useEffect(() => {
    if (posterWithOpenMenu == show.title) {
      setShouldDisplayMenu(true);
    } else {
      setShouldDisplayMenu(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posterWithOpenMenu])

  const handleKabobClick = () => {
    // Set or unset clicked show as posterWithOpenMenu
    if (posterWithOpenMenu === show.title) {
      // if the menu is already open, the menu will close
      setPosterWithOpenMenu('');
    } else {
      // if the menu is not open, open it
      setPosterWithOpenMenu(show.title)
    }
  }

  return (
    <article className={articleClasses} data-testid="rtl-posterWithMenu">
      <a
        className={posterClasses}
        href={watchUrl}
        data-gtm-action={posterGTMAction}
      >
        <ImageWithFallback
          customClass="poster-with-menu__content"
          isBroken={hasBrokenImage}
          onBrokenImage={setHasBrokenImage}
          textOverlay={true}
          path={show['show-poster2x3']}
          crop="?format=auto&crop=205x308"
          title={show.title}
        />
        {!userMenuLayout && (
          <div className={badgeClasses}>
            <span className="poster-with-menu__badge-text">
              {width > 1279 ? badgeText : badgeTextMin}
            </span>
          </div>
        )}
        {shouldDisplayProgressBar ? (
          <ProgressBar
            percentComplete={percentComplete}
          />
        ) : null}
      </a>
      {shouldDisplayMenu && (
        <Menu
          show={show}
          watchUrl={watchUrl}
          menuText={menuText}
          removeText={removeText}
          layout={width > 767 ? 'desktop' : 'mobile'}
          closeMenu={() => setShouldDisplayMenu(false)}
          videoCid={videoCid}
          showCid={showCid}
          hideParent={hideParent}
        />
      )}
      <div className={menuClasses}>
        <a
          className={textLinkClasses}
          href={watchUrl}
          data-gtm-action={textLinkGTMAction}
        >
          <PlayCircleIcon />
          <span className="poster-with-menu__menu-button__text">{videoSummary}</span>
        </a>
        {!userMenuLayout && (
          <button
            className="poster-with-menu__menu-button__kabob an-61_5"
            onClick={handleKabobClick}
            data-gtm-action={`Continue Watching | Menu Button | Menu Title`}
            data-gtm-label={watchUrl}
          >
            <KabobMenu />
          </button>
        )}
      </div>
    </article>
  );
}

export default PosterWithMenu;