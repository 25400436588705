import React, { useState, useEffect } from 'react';
import { Transition } from 'react-transition-group';

import { customEvents } from 'scripts/custom-events';
import { formatTime } from 'scripts/utils/formatTime';
import { savedSecondaryTimezoneMatches } from 'scripts/utils/savedSecondaryTimezoneMatches';

interface TimeProps {
  secondaryTimezone?: string;
  animationDuration?: number;
  className?: string;
  dateTime: string;
  format?: string;
  depSavedSecondaryTimezoneMatches?: boolean;
}

const Time: React.FC<TimeProps> = (props) => {
  const {
    dateTime,
    secondaryTimezone,
    depSavedSecondaryTimezoneMatches,
    animationDuration,
    className,
    format,
  } = props;

  let timeString = '';

  const [secondaryTimezoneSelected, setSecondaryTimezoneSelected] =
    useState(false);
  const [animating, setAnimating] = useState(false);

  const duration = animationDuration ? animationDuration : 300;

  const handleTimeToggle = () => {
    // first we need to start to fade out
    setAnimating(true);

    // then delay by the duration the state switch
    setTimeout(() => {
      if (
        depSavedSecondaryTimezoneMatches ||
        savedSecondaryTimezoneMatches(secondaryTimezone)
      ) {
        setSecondaryTimezoneSelected(true);
      } else {
        setSecondaryTimezoneSelected(false);
      }
    }, duration);

    // then delay the fade back in
    setTimeout(() => {
      setAnimating(false);
    }, duration);
  };

  useEffect(() => {
    window.addEventListener(customEvents.timezoneToggle, handleTimeToggle);

    if (savedSecondaryTimezoneMatches(secondaryTimezone)) {
      setSecondaryTimezoneSelected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondaryTimezone]);

  if (secondaryTimezoneSelected) {
    timeString = formatTime(dateTime, format, secondaryTimezone) as string;
  } else {
    timeString = formatTime(dateTime, format) as string;
  }

  let transitionproperties = `opacity, transform`;

  if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
    transitionproperties = `opacity`;
  }

  const defaultStyle = {
    display: `inline-block`,
    transitionProperty: transitionproperties,
    transitionDuration: `${duration}ms`,
    transtionTimingFunction: `ease-in-out`,
  };

  // TS needs this to be happy, and I can't find clear examples of how to
  // use react-transition-group with typescript properly
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const transitionStyles: any = {
    entering: {
      opacity: 0,
      transform: `rotateX(-90deg)`,
    },
  };

  return (
    <React.Fragment>
      <Transition timeout={duration} in={animating}>
        {(state) => (
          <span
            style={{ ...defaultStyle, ...transitionStyles[state] }}
            className={className}
          >
            {timeString}
          </span>
        )}
      </Transition>
    </React.Fragment>
  );
};

export default Time;
