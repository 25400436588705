import Cookies from 'js-cookie';
import { getCookieDomain } from 'scripts/utils/getCookieDomain';
import { isLocalStorageSupported } from 'scripts/utils/storage';

interface CacheExpectations {
  body?: HTMLBodyElement;
  modal?: HTMLElement;
  messages?: NodeListOf<HTMLElement>;
}

interface ConfigExpectations {
  defaults: {
    code: string;
    action: string;
    type: string;
    url: string;
  },
  domain?: string;
  csrftoken: string;
  cookies: {
    [key: string]: {
      name: string;
      duration: number;
    }
  },
}

/**
 * Reference object for re-usable elements.
 * @type {CacheExpectations} cache
 */
const cache: CacheExpectations = {};

/**
 * Reference object for static data.
 * @type {ConfigExpectations}
 */
const config: ConfigExpectations = {
  defaults: {
    // valid options are 'LNK' or 'MVOD'
    code: 'LNK',
    action: 'Home',
    type: 'sign-in',
    url: window.location.href,
  },
  domain: getCookieDomain(),
  csrftoken: Cookies.get('csrftoken'),
  cookies: {
    redirect: {
      name: 'pbsol.redirect_url',
      duration: 360,
    },
    campaign: {
      name: 'tmp_campaign_info',
      duration: 10,
    },
  },
};

/**
 * Sets up cache object of element references.
 */
const setupCache = () => {
  cache.modal = document.querySelector('#signInModalWindow');
  cache.messages = document.querySelectorAll('#signInModalWindow .copy-message');
  cache.body = document.querySelector('body');
};

/**
 * Update modal messaging for various scenarios
 * @param {string} messageTypeToShow - class of message div to show
 */
const updateMessaging = (messageTypeToShow: string) => {
  // first hide all the messages
  cache.messages.forEach((message) => message.classList.add('is-hidden'));
  // then show only the correct message
  const message = document.querySelector(`.sign-in__message.${messageTypeToShow}`);
  message && message.classList.remove('is-hidden');
};

/**
 * Sets cookie with config defined above.
 * @param {string} cookieKey - cookie to set
 * @param {string | null} val - value to set cookie to
 */
const setCookieData = (cookieKey: string, val: string | null) => {
  const cookie = config.cookies[cookieKey];
  Cookies.set(cookie.name, val, {
    expires: cookie.duration,
    path: '/',
    domain: config.domain,
    secure: true,
    sameSite: 'None',
  });
};

/**
 * Sets cookies needed to redirect after sign-in flow.
 * @param {string} campaignCode - campaign code
 * @param {string} action - @todo find out what this does
 * @param {string} message - @todo find out what this is
 * @param {string} redirectUrl - if redirected, page to go to
 */
const signIn = (campaignCode: string, action: string, message: string, redirectUrl: string): void => {
  // why are we setting this?
  window.GAaction = action || '';

  const url = redirectUrl || window.location.href;

  setCookieData('redirect', url);

  // localStorage flag to indicate that the user is attempting to log in
  if (isLocalStorageSupported()) {
    // localStorage does not accept booleans, only strings
    // https://github.com/microsoft/TypeScript/issues/26084
    // so we need to set and check for the string 'true', not bool
    localStorage.setItem('tryingToLogin', 'true');
  }

  if (campaignCode) {
    setCookieData('campaign', campaignCode);
  }
};

/**
 * Signs out of profile service.
 */
const signOut = (data?: {redirectUrl?: string}): void => {
  fetch('/logout/', {
    method: 'POST',
  }).then((response) => {
    if (data && data.redirectUrl) {
      location.href = data.redirectUrl;
    } else {
      location.reload();
    }
    return response;
  });
};

/**
 * Inits sign in.
 */
const init = (): void => {
  setupCache();
};

/**
 * On show public method.
 * @param {object} data - custom data
 */
const onShow = (data?: {type?: string}): void => {
  const updatedData = Object.assign({}, config.defaults, data);

  // hide any nav if it is open
  cache.body.classList.remove('nav-is-open');

  // show correct message
  updateMessaging(updatedData.type);

  // set redirect url and cookies
  signIn(
    updatedData.code,
    updatedData.action,
    updatedData.type,
    updatedData.url
  );
};

/**
 * On hide public method.
 */
const onHide = (): void => {
  // update cookie for redirect
  setCookieData('redirect', '');
};

export { init, onShow, onHide, signIn, signOut };
