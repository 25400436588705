import { customEvents } from 'scripts/custom-events';
import { storageAvailable } from 'scripts/utils/storage';
import { getUserId } from 'scripts/utils/getUserId';

enum UserPrefs {
  SYSTEM = `system`,
  DARK = `dark`,
  LIGHT = `light`,
}

type UserPreferences = `${UserPrefs}`;
interface CacheExpectations {
  html?: HTMLHtmlElement;
  appearanceToggleSections?: NodeListOf<HTMLDivElement>;
  appearanceToggleRadios?: NodeListOf<HTMLInputElement>;
}

const cache: CacheExpectations = {};

// A general comment about the state of the user's appearance preference:
// The idea is that if the user _chooses_ a dark or light theme, that
// choice is saved in local storage. However, if they choose the system option,
// or they make no choice, that is signified by the fact that there is no
// appearance option in the user's local storage. The functions below operate under
// that idea of state.

/**
 * Caches re-used elements.
 */
const setupCache = (): void => {
  cache.html = document.querySelector('html');
  cache.appearanceToggleSections = document.querySelectorAll('.appearance-toggle');
  cache.appearanceToggleRadios = document.querySelectorAll('.appearance-toggle__radio');
};

/**
 * Toggling the radio button for user appearance on load
 * @returns {void}
*/
const userAppearanceToggleInit = (): void => {
  const pref = localStorage.getItem('appearance') as UserPreferences;

  cache.appearanceToggleSections.forEach((section) => section.classList.remove('is-hidden'));

  cache.appearanceToggleRadios.forEach((radio) => {
    if (
      // if the preference matches that of the radio in the loop
      (pref && pref === radio.value) ||
      // or if there is no preference and this radio is the system option
      (!pref && radio.value === UserPrefs.SYSTEM)
      ) {
      radio.checked = true;
    }
  });
}

/**
 * Toggling the radio button for user appearance on pref change
 * @param {CustomEvent} e - custom emitted event
 * @returns {void}
*/
const onUserAppearancePrefChange = (e: CustomEvent): void => {
  const pref = e.detail.userPref as UserPreferences;
  cache.appearanceToggleRadios.forEach((radio) => {
    // if the preference matches that of the radio in the loop
    if (pref && pref === radio.value) {
      radio.checked = true;
    }
  });
}

/**
 * Toggles the appearance classes on the HTML element
 * @param {Event} e - click event from button
 * @returns {void}
 */
const onUserAppearanceToggle = (e:Event): void => {
  const pref = (<HTMLInputElement>e.target).value as UserPreferences;

  const userPrefEvent = new CustomEvent(customEvents.userAppearancePrefChange, {
    detail: {
      userPref: pref
    }
  })

  // remove existing appearance classes
  cache.html.classList.remove(`appearance--${UserPrefs.DARK}`, `appearance--${UserPrefs.LIGHT}`);
  // emit custom event that the user changed their preference
  window.dispatchEvent(userPrefEvent);

  if (pref === UserPrefs.SYSTEM) {
    localStorage.removeItem('appearance');
  } else if (pref === UserPrefs.DARK || pref === UserPrefs.LIGHT) {
    localStorage.setItem('appearance', pref)
    cache.html.classList.add(`appearance--${pref}`);
  }
}

/**
 * Adds event handlers.
 */
const addEvents = (): void => {
  cache.appearanceToggleRadios.forEach((radio) => radio.addEventListener('input', onUserAppearanceToggle));
  window.addEventListener('userAppearancePrefChange', onUserAppearancePrefChange);
};

/**
 * On init.
 */
const init = (): void => {
  setupCache();
  // this all only works if the user lets us use localStorage
  // and they're logged in (which we're guessing by way of userId cookie)
  if (storageAvailable('localStorage') && getUserId()) {
    addEvents();
    userAppearanceToggleInit();
  } else {
    // If the user does not have storage available, just remove the appearance section
    cache.appearanceToggleSections.forEach((section) => section.remove())
  }
};

export { init };
