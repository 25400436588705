import queryString from 'query-string';

type Config = {
  params?: {
    donate?: string,
    returnURL?: string,
    showLocalized?: string,
    video_end?: string,
  }
}

/**
 * Config for default localization values.
 * @type {object} config
 */
const config: Config = {};

/**
 * Checks if JAWS has been inited.
 * @returns {boolean}
 */
const jawsInited = (): boolean => {
  return typeof window.JAWS !== 'undefined';
};

/**
 * Calls localization with redirect page if needed.
 */
const callLocalization = (): void => {
  const page = config.params.donate === 'true' ? 'Donate' : null;

  window.JAWS.Localization.init(page, config.params.returnURL, window.PBS_COUNTRY_ID);
};

/**
 * Checks if redirect sent from the player upsell overlay.
 */
const checkIfRedirectedFromDonate = () => {
  if (config.params.showLocalized === 'true' && config.params.returnURL) {
    if (config.params.video_end === 'true') {
      window.VIDEO_END_DONATE = 'True';
    }
    if (jawsInited()) {
      callLocalization();
    } else {
      const interval = setInterval(function () {
        if (jawsInited()) {
          callLocalization();
          clearInterval(interval);
        }
      }, 100);

      // wait 5 seconds for jaws to init before quitting
      setTimeout(function () {
        clearInterval(interval);
      }, 5000);
    }
  }
};

/**
 * Inits additional localization for each page.
 */
const init = (): void => {
  config.params = queryString.parse(window.location.search);
  checkIfRedirectedFromDonate();
};

export { init };
