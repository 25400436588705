import React from 'react';

interface CaretRightProps {
  className?: string;
}

const CaretRight:React.FC<CaretRightProps> = (props) => {
  const { className } = props;

  let classNames = 'pbs-right-caret';

  if (className) {
    classNames += ` ${className}`;
  }

  return (
    <svg
      width="10.5"
      height="18.6"
      viewBox="0 0 10.5 18.6"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames}
      aria-hidden="true"
    >
      <path
        d="M10.3 10.2c.5-.7.2-1.4-.3-2L2.4.4C1-.8-.8.9.4 2.4c.3.4 6.8 6.8 6.8 7 0 .3-6.3 6.6-6.7 7-1.2 1.2.7 3 1.9 1.9a148 148 0 007.9-8.1z"
        fill="#222"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default CaretRight;
