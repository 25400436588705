import { isTouchDevice } from 'scripts/utils/isTouchDevice';
import { isTabKeyPress } from 'scripts/utils/isTabKeyPress';
import _debounce from 'lodash.debounce';
import _isPlainObject from 'lodash.isplainobject';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IAny = any;
interface ModalOptions extends IAny {
  modalId?: string;
  modalTrigger?: string | Element;
  focusTarget?: string;
  lastFocusableEl?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  childView?: any;
}

interface Defaults {
  [key: string]: string | undefined | boolean | HTMLHtmlElement | Record<string, unknown>;
}
interface ModalInterface {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [key: string]: object;
  updateDefaults: (
    arg0: ModalInterface,
    arg1: string | boolean | Record<string, unknown> | HTMLHtmlElement,
    arg2: ModalOptions | string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    arg3?: object | string | boolean,
  ) => void;
}

interface ChildView {
  [key: string]: () => void;
}

/**
 * Class for .org Modal implementation.
 */
// Still open TS issue that makes this hard: https://github.com/microsoft/TypeScript/issues/15300

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
class Modal implements ModalInterface {
  onWindowResizeDebounced: () => void;
  childView: {
    init: () => void;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onShow: (arg0?: object) => void;
    onHide: () => void;
  };
  modalId: string;
  modal: HTMLElement;
  lastFocusableEl: HTMLElement | string;
  focusTarget: HTMLElement | string;
  modalTrigger: HTMLElement;
  modalBody: HTMLElement;
  modalScrollable: HTMLElement;
  documentHtml: HTMLHtmlElement;
  closeButtons: NodeListOf<HTMLButtonElement>;

  /**
   * Initializes class.
   * @param {object} options - custom settings passed in (optional)
   * @constructor
   */
  constructor(options: ModalOptions) {
    if (options) {
      this.setupProps(options);
    }

    // bind instance method to 'this' in constructor
    // see this http://alexfedoseev.com/post/65/react-event-handlers-and-context-binding
    this.bindToContext(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this,
      'hide',
      'onEscapeClick',
      'onBackgroundClick',
      'onLastFocusableElTabPress',
      'onModalShiftTabPress'
    );
    this.onWindowResizeDebounced = _debounce(
      this.onWindowResize.bind(this),
      10
    );

    // if child view was passed in, init it
    if (this.childView && this.childView.init) {
      this.childView.init();
    }

    return this;
  }

  /**
   * Bind methods to same instance of this
   * for removing event listeners.
   * @param {object} context - this
   * @param {object} ...methods - event functions that require binding of this
   */
  bindToContext(context: ChildView, ...methods: Array<string>): void {
    methods.map((method) => {
      context[method] = context[method].bind(context);
    });
  }

  /**
   * Sets up default properties.
   * @param {object} options - any custom options passed in
   */
  setupProps(options: ModalOptions): void {
    const defaults: Defaults = {
      documentHtml: document.querySelector('html'),
      modalId: undefined,
      closeButtons: undefined,
      outsideModal: true,
      modalTrigger: undefined,
      focusTarget: undefined,
      lastFocusableEl: undefined,
      childView: undefined,
      settings: {
        scroll: false,
        accessibility: true,
        phead: false,
      },
    };

    // loop through all defaults and set value to any options passed in
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.updateDefaults(this, defaults, options);

    this.cacheEls();
  }

  /**
   * caching some of the Options from setupProps
   */
  cacheEls(): void {
    if (this.modalId) {
      this.modal = document.querySelector(this.modalId);
      this.modalBody = document.querySelector(
        `${this.modalId} .modal-window__body`
      );
      this.modalScrollable = document.querySelector(
        `${this.modalId} .modal-window__dialog--scrollable`
      );
      this.closeButtons = document.querySelectorAll(
        `${this.modalId} .close-modal`
      ); // can have multiple 'close' buttons
    }

    if (this.focusTarget) {
      this.focusTarget = document.querySelector(this.focusTarget as string) as HTMLElement;
    }

    if (typeof this.modalTrigger === 'string') {
      this.modalTrigger = document.querySelector(this.modalTrigger);
    }

    if (this.lastFocusableEl) {
      this.lastFocusableEl = document.querySelector(this.lastFocusableEl as string) as HTMLElement;
    } else {
      this.lastFocusableEl = this.closeButtons[0];
    }
  }
  /**
   * Updates defaults to override with options passed in and sets to this instance.
   * @param {object} modal - current modal instance
   * @param {object} defaults - default values
   * @param {object} options - options values passed in
   * @param {object} context - if nested object,
   * this context will be the higher level key (optional)
   * @todo investigate using Set
   *
   */
  updateDefaults(modal: ModalInterface, defaults: Defaults, options: ModalOptions, context?: {[key: string]: string | boolean;}): void {
    // if no context passed in
    // then we are on the top level of the modal object
    const currentContext = context ? context : modal;

    // loop through defaults
    for (const key in defaults) {
      if (Object.prototype.hasOwnProperty.call(defaults, key)) {
        // if the value of the key is a nested object
        // use this function recursively to loop through again
        if (_isPlainObject(defaults[key])) {
          modal[key] = {};
          modal.updateDefaults(modal, defaults[key], options[key], modal[key]);
        } else {
          // update the keys value
          currentContext[key] =
            options && typeof options[key] !== 'undefined'
              ? options[key]
              : defaults[key];
        }
      }
    }
  }

  /**
   * On window resize, don't let modal go off screen
   */
  onWindowResize(): void {
    if (this.allowScrolling()) {
      this.modalScrollable.classList.add('modal-window__dialog--has-scroll');
    } else {
      this.modalScrollable.classList.remove('modal-window__dialog--has-scroll');
    }
  }

  /**
   * Adds event listeners.
   */
  addEvents(): void {
    this.closeButtons.forEach((button) =>
      button.addEventListener('click', this.hide)
    );
    document.addEventListener('keyup', this.onEscapeClick);
    this.modal.addEventListener('click', this.onBackgroundClick);
    window.addEventListener('resize', this.onWindowResize.bind(this));

    if (this.focusTarget) {
      (this.focusTarget as HTMLElement).addEventListener('keydown', this.onModalShiftTabPress);
    }

    if (this.lastFocusableEl) {
      (this.lastFocusableEl as HTMLElement).addEventListener(
        'keydown',
        this.onLastFocusableElTabPress
      );
    }
  }

  /**
   * Removes event listeners.
   */
  removeEvents(): void {
    this.closeButtons.forEach((button) =>
      button.removeEventListener('click', this.hide)
    );
    document.removeEventListener('keyup', this.onEscapeClick);
    this.modal.removeEventListener('click', this.onBackgroundClick);
    window.removeEventListener('resize', this.onWindowResize.bind(this));

    if (this.focusTarget) {
      (this.lastFocusableEl as HTMLElement).removeEventListener(
        'keydown',
        this.onLastFocusableElTabPress
      );
      (this.focusTarget as HTMLElement).removeEventListener(
        'keydown',
        this.onModalShiftTabPress
      );
    }
  }

  /**
   * Determines if should allow scrolling
   * @returns {boolean} - if modal height is taller than window
   */
  allowScrolling(): boolean {
    return this.modalBody.offsetHeight > window.innerHeight;
  }

  /**
   * Makes modal visible through toggling classes
   */
  addVisibleClass(): void {
    this.modal.classList.add('is-visible');
    this.documentHtml.classList.add('has-visible-modal');

    if (this.allowScrolling()) {
      this.modalScrollable.classList.add('modal-window__dialog--has-scroll');
    }
  }

  /**
   * Hides modal through toggling classes
   */
  removeVisibleClass(): void {
    this.modal.classList.remove('is-visible');
    this.documentHtml.classList.remove('has-visible-modal');

    if (
      this.modalScrollable.classList.contains(
        'modal-window__dialog--has-scroll'
      )
    ) {
      this.modalScrollable.classList.remove('modal-window__dialog--has-scroll');
    }
  }

  /**
   * When user clicks outside modal, calls the function to close modal
   * @param {event} e
   */
  onBackgroundClick(e: Event): void {
    // cache this for later use
    this.modalBody =
      this.modalBody || this.modal.querySelector('.modal-window__body');

    if (this.modal.classList.contains('is-visible')) {
      // if clickout outside the modal, hide the modal
      if ((e.target as HTMLElement).classList.contains('modal-window__dialog--scrollable')) {
        this.hide(e);
      }
    }
  }

  /**
   * calls the close function when the escape key is pressed
   * @param {event} e
   */
  onEscapeClick(e: KeyboardEvent): void {
    e.preventDefault();

    // checks if modal is open
    if (this.modal.classList.contains('is-visible')) {
      // checks if key clicked is the escape key
      if (e.keyCode === 27) {
        this.hide(e);
      }
    }
  }

  /**
   * Passes focus to the last focusable element when on the focusTarget, and shift-tab is pressed
   * @param {event} e
   */
  onModalShiftTabPress(e: KeyboardEvent): void {
    // keep focus within the modal's elements
    if (isTabKeyPress(e, true) && this.focusTarget === e.target) {
      e.preventDefault();
      (this.lastFocusableEl as HTMLElement).focus();
    }
  }

  /**
   * Passes focus to the focusTarget when on the last focusable element, and tab is pressed
   * The default for this is the close button, but an alternative can be specified
   * @param {event} e
   */
  onLastFocusableElTabPress(e: KeyboardEvent): void {
    // if we have a focusTarget, pass focus back to it
    if (isTabKeyPress(e) && this.focusTarget) {
      e.preventDefault();
      (this.focusTarget as HTMLElement).focus();
    }
  }

  /**
   * closes the modal
   * @param {event} e
   */
  hide(e: Event): void {
    e.preventDefault();
    this.removeEvents();
    this.removeVisibleClass();

    if (this.childView && this.childView.onHide) {
      this.childView.onHide();
    }

    // For keyboard navigation, focus returns to the trigger for the modal
    if (!isTouchDevice()) {
      if (this.modalTrigger) {
        this.modalTrigger.focus();
      }
    }
  }

  /**
   * Makes the modal visible
   * @param {object} data - any data to pass in
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  show(data: object): void {
    const customData = data || {};

    this.addVisibleClass();
    this.addEvents();

    if (this.childView && this.childView.onShow) {
      this.childView.onShow(customData);
    }

    // For keyboard navigation, passes focus to the modal
    if (!isTouchDevice()) {
      if (this.focusTarget) {
        (this.focusTarget as HTMLElement).focus();
      }
    }
  }
}

export default Modal;
