/**
 * Takes a number of seconds and returns a formatted
 * version of the duration.
 * @param {duration} number - the duration of a video, in seconds
 * @returns {string}
 */
const formatDuration = (duration: number): string => {
  // Intended outputs:

  //     1h 20m 35s
  //     1h 30s  (there are no minutes, e.g. 1 hour and 30 seconds)
  //     1h 20m  (there are no seconds, e.g. exactly 1 hour and 20 minutes)
  //     7m 6s   (there are no hours)
  //     35s
  //     9s  (no leading zeros)

  // if the duration isn't passed return empty string
  if (!duration) {
    return ``;
  }

  const time = [];

  // get the number of hours, minutes, seconds
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor((duration % 3600) % 60);

  // if any of the units of time exist, push them to time
  if (hours) {
    time.push(`${hours}h`);
  }

  if (minutes) {
    time.push(`${minutes}m`);
  }

  if (seconds) {
    time.push(`${seconds}s`);
  }

  return time.join(' ');
};

export { formatDuration };
