import ResponsiveAd from './ad-responsive';

const AdhesionAd = class AdhesionAd extends ResponsiveAd {
  adhesionCloseLink: null | HTMLElement;

  /**
   * Initializes class instance.
   * param {object} options - any custom options passed in
   * @overrides
   */
  constructor(options: Defaults) {
    super(options);

    const defaults: Defaults = {
      type: 'adhesion',
      adhesionCloseLink: null,
      mappingSizes: {
        mobile: [
          [320, 400],
          [320, 50],
        ],
        tablet: [
          [750, 200],
          [728, 90],
        ],
        desktop: [[1024, 200], []],
      },
    };

    // loop through all defaults and set value to any options passed in
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.updateDefaults(this, defaults, options);

    this.onAdhesionCloseClick = this.onAdhesionCloseClick.bind(this);
  }

  /**
   * Sets reference for close link.
   */
  setAdhesionCloseLink(): void {
    this.adhesionCloseLink = document.getElementById('close-adhesion-ad');
  }

  /**
   * On adhesion close link click.
   * @param {Event} e
   */
  onAdhesionCloseClick(e: Event): void {
    e.preventDefault();

    // remove element and event listener
    this.adhesionCloseLink.removeEventListener(
      'click',
      this.onAdhesionCloseClick
    );
    this.el.remove();
  }

  /**
   * Adds event handlers.
   */
  addEvents(): void {
    // if adhesion link exists (doesn't exist on desktop)
    if (this.adhesionCloseLink) {
      this.adhesionCloseLink.addEventListener(
        'click',
        this.onAdhesionCloseClick
      );
    }
  }

  /**
   * Renders ad.
   * @override - overrides render in ResponsiveAd class
   */
  // returning any here because TS just does not want to play nice with class inheritance
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render(): any {
    super.render();

    this.setAdhesionCloseLink();
    this.addEvents();
  }
};

export default AdhesionAd;
