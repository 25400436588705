import _debounce from 'lodash.debounce';
import * as SideNav from './navigation-side';
import * as Station from './station';
import * as UserMenu from './user-menu';
import * as TVSchedules from './tv-schedules';
import { isViewportOver } from 'scripts/utils/isViewportOver';
import { isTouchDevice } from 'scripts/utils/isTouchDevice';
import { hideStationMenu } from './hideStationMenu';

interface CacheExpectations {
  body?: HTMLBodyElement;
  brandAndSignInLink?: HTMLLinkElement;
  navItemsList?: HTMLUListElement;
  mainContent?: HTMLElement;
  searchInput?: HTMLInputElement;
  searchListItem?: HTMLElement;
  tvSchedulesDisabledBtn?: HTMLElement;
  donateUnlocalized?: HTMLElement;
  navItems?: HTMLElement;
  navItemLinks?: NodeListOf<HTMLLinkElement>;
  dropdowns?: HTMLElement;
  navFocusTargets?: NodeListOf<HTMLElement>;
}

const cache: CacheExpectations = {};

/**
 * Caches re-used elements.
 */
const setupCache = () => {
  cache.body = document.querySelector('body');
  cache.brandAndSignInLink = document.querySelector('#userDropdownLoggedOut');
  cache.navItemsList = document.querySelector('.page-header__nav-items');
  cache.mainContent = document.querySelector('#maincontent');
  cache.searchInput = document.querySelector('#header-search'); // need to select the non-mobile search field
  cache.searchListItem = document.querySelector('.nav-item--search.nav-item--desktop'); // need to select the non-mobile search field
  cache.tvSchedulesDisabledBtn = document.querySelector('#tvSchedules');
  cache.donateUnlocalized = document.querySelector('#donateUnlocalized');
  cache.navItems = document.querySelector('.nav-item');
  cache.navItemLinks = document.querySelectorAll(
    '.nav-item__link, .tv-schedules--disabled-btn, .donate--disabled-btn'
  );
  cache.dropdowns = document.querySelector('.dropdown');
  cache.navFocusTargets = document.querySelectorAll(
    'main, .page-header__nav-items, #userDropdownLoggedOut, #userDropdown'
  );
};

/**
 * Closes both the station and the user menu, depending on body classes that are present.
 * Used in both mobile and desktop breakpoints.
 */
const closeStationAndUserMenus = () => {
  if (cache.body.classList.contains('nav-is-open')) {
    SideNav.hideNav();
  } else if (cache.body.classList.contains('station-is-open')) {
    hideStationMenu();
  } else if (cache.body.classList.contains('desktop-user-menu-is-open')) {
    UserMenu.hideDesktopUserMenu();
  }
};

/**
 * This is triggered by clicking on the nav items area.
 * We're basically making sure that they haven't clicked on the User button.
 * It's nav item has a class of "prevent-close".
 */
const onNavItemsClick = (e: Event) => {
  // target is the thing that has been clicked on, which can be either the ul.nav-items, a .nav-item,
  // or more specifically the .nav-item.prevent-close
  const target = e.target as HTMLElement;

  // we need to evaluate on each click/tap whether or not the viewport is over $md (1024px)
  if (isViewportOver(1024)) {
    // if there is NOT a parent with the class "prevent-close" (which the user nav item *does*)
    // we will close *all* the menus
    if (!target.closest('.prevent-close')) {
      closeStationAndUserMenus();

      // Otherwise we can assume the user has clicked on the user nav item, or an element within it
      // If the body has the class indicating that the desktop user menu is open,
      // hide the desktop user menu
    } else if (
      cache.body.classList.contains('desktop-user-menu-is-open') &&
      !isTouchDevice()
    ) {
      UserMenu.hideDesktopUserMenu();
    }
  }
};

/**
 * Keeps search menu open
 */
const keepSearchDropdownOpen = () => {
  cache.searchListItem.classList.add('is-open');
};

/**
 * Makes sure the search dropdown stays open if the user has started to fill it in
 */
const shouldSearchDropdownBeOpen = () => {
  if (cache.searchInput.value !== '') {
    keepSearchDropdownOpen();
  } else {
    cache.searchListItem.classList.remove('is-open');
  }
};

/**
 * Attaches 'scrolled' class to body if the user has scrolled down at all
 */
const onWindowScroll = () => {
  const scrolled = window.scrollY > 0;

  scrolled ?
    cache.body.classList.add('scrolled') :
    cache.body.classList.remove('scrolled');
}

/**
 * Handles the window expanding past 1023px wide while a menu is open
 */
const onWindowResize = () => {
  if (isViewportOver(1024)) {
    SideNav.hideNav();
    hideStationMenu();
    cache.body.addEventListener('click', closeStationAndUserMenus);
    cache.navItemLinks.forEach((navItemLink) => {
      navItemLink.addEventListener('focus', dropdownOnFocus);
    });
    cache.brandAndSignInLink.addEventListener(
      'focus',
      closeStationAndUserMenus
    );
  } else {
    //removing this event listeners for mobile users
    cache.body.removeEventListener('click', closeStationAndUserMenus);

    cache.navItemLinks.forEach((navItemLink) => {
      navItemLink.removeEventListener('focus', dropdownOnFocus);
    });
    cache.brandAndSignInLink.removeEventListener(
      'focus',
      closeStationAndUserMenus
    );
  }
};

/**
 * This happens when there is no localization
 */
const initJAWS = (e: Event, msg: string) => {
  if (window.JAWS && window.JAWS.Localization) {
    window.JAWS.Localization.init(msg, '', window.PBS_COUNTRY_ID);
  }
};

const onTvSchedulesDisabledBtnClick = (e: Event) => {
  initJAWS(e, 'TVSchedules');
};

/**
 * Closes all dropdowns and removes the focus event listener from main
 */
const closeAllDropdowns = () => {
  // removing event listener
  cache.navFocusTargets.forEach((focusTarget) => {
    focusTarget.removeEventListener('focus', closeAllDropdowns);
    focusTarget.removeEventListener('click', closeAllDropdowns);
  });

  // removing classes from nav and dropdowns
  const openNavItems = document.querySelectorAll('.nav-item.is-open');
  openNavItems.forEach((item) => item.classList.remove('is-open'));
  // checking for input in the search box
  shouldSearchDropdownBeOpen();
  const openDropdowns = document.querySelectorAll('.dropdown.is-open');
  openDropdowns.forEach((dropdown) => dropdown.classList.remove('is-open'));
  // close the station menu
  cache.body.classList.remove('station-is-open');
  // close the user menu
  UserMenu.hideDesktopUserMenu();
};

/**
 * Closes any dropdowns already open
 */
const closeAnyOpenSiblingDropdowns = () => {
  const previouslyOpenItem = document.querySelector('.nav-item.is-open');
  const previouslyOpenDropdown = document.querySelector('.dropdown.is-open');

  if (previouslyOpenItem) {
    previouslyOpenItem.classList.remove('is-open');
  }

  if (previouslyOpenDropdown) {
    previouslyOpenDropdown.classList.remove('is-open');
  }
};

/**
 * Invokes dropdowns when a nav item is in focus
 * @param {event} e
 */
const dropdownOnFocus = () => {
  // close the station or user menu
  closeStationAndUserMenus();

  // close any sibling dropdowns
  closeAnyOpenSiblingDropdowns();

  // adding event listener to main element area, the space around the nav items,
  // and user buttons
  // so that any dropdowns are closed
  cache.navFocusTargets.forEach((focusTarget) => {
    focusTarget.addEventListener('focus', closeAllDropdowns);
    focusTarget.addEventListener('click', closeAllDropdowns);
  });
};

/**
 * Adds event handlers.
 */
const addEvents = () => {
  if (isTouchDevice()) {
    // Close menus if user taps in main content while a menu is open
    cache.mainContent.addEventListener('touchend', closeAllDropdowns);

    // Close menus if user taps on other nav items while menu is open
    cache.navItemsList.addEventListener('touchend', onNavItemsClick);
  } else if (isViewportOver(1024)) {
    // Close menus if user clicks in main content while a menu is open
    cache.body.addEventListener('click', closeStationAndUserMenus);

    cache.navItemLinks.forEach((navItemLink) => {
      navItemLink.addEventListener('focus', dropdownOnFocus);
    });

    // closes station menu when tabbing back to the brand link
    cache.brandAndSignInLink.addEventListener(
      'focus',
      closeStationAndUserMenus
    );

    // if the search input is in focus, we always want it open.
    // this enables right-click filling of the input
    cache.searchInput.addEventListener('focus', keepSearchDropdownOpen);

    // when someone is entering search terms, or clearing them,
    // we need to figure out of the search drop down should stay open
    cache.searchInput.addEventListener('blur', shouldSearchDropdownBeOpen);
  }


  // Close menus if page expands past 1023px wide
  window.addEventListener('resize', _debounce(onWindowResize, 100));

  window.addEventListener('scroll', _debounce(onWindowScroll, 50));

  // when tv schedules button is clicked and user is not localized
  if (cache.tvSchedulesDisabledBtn) {
    cache.tvSchedulesDisabledBtn.addEventListener(
      'click',
      onTvSchedulesDisabledBtnClick
    );
  }
};

/**
 * On init.
 */
const init = (): void => {
  setupCache();
  addEvents();
  SideNav.init();
  UserMenu.init();
  Station.init();
  TVSchedules.init();
};

export { init };
