import { setupVideoSummaryPopover } from 'components/popover/popover';

interface CacheExpecations {
  videoSummaries?: NodeListOf<HTMLElement>;
}

const cache: CacheExpecations = {};

const setupCache = (parentContainer: Element) => {
  cache.videoSummaries = parentContainer.querySelectorAll('.video-summary');
};

const setupTooltips = () => {
  cache.videoSummaries.forEach((videoSummary) => {
    // for all videos, add a regular video summary popover
    setupVideoSummaryPopover(videoSummary);
  });
};

const init = (parentContainer: Element): void => {
  setupCache(parentContainer);
  setupTooltips();
};

export { init };
