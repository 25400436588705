/**
 * Checks if if it's a Tab press and not a shift tab press
 * @param {event} e - Keyboard event
 * @param {boolean} shift - Optional test for shift key, defaults to false
 * @returns {boolean} boolean
*/
const isTabKeyPress = (e:KeyboardEvent | React.KeyboardEvent<HTMLElement>, shift = false): boolean => {
  // Excluding shift key
  if (!shift) {
    return e.key === 'Tab' && !e.shiftKey;
  } else {
  // Including shift key
    return e.key === 'Tab' && e.shiftKey;
  }
}

export { isTabKeyPress };
