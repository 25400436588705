import React from 'react';
import Cookies from 'js-cookie';
import queryString from 'query-string';

/**
 * Cache for referenced elements.
 * @type {object} cache
 */
interface CacheExpectations {
  iframe?: HTMLIFrameElement
}

const cache: CacheExpectations = {};

/**
 * Setup cache of referenced elements.
 */
const setupCache = () => {
  cache.iframe = document.querySelector('.video-player__iframe');
};

interface Config {
  autoplay?: boolean;
  embedURL?: string;
  embedType?: string;
  id?: string;
  slug?: string;
  stationVideoPortalCallsign?: string;
  stationId?: string;
  disableContinuousPlay?: boolean;
}

/**
 * Creates the base player url
 * @param {Config} - the configuration object, usually passed from window
 * @returns {string}
 */
const buildPlayerBaseUrl = (config: Config): string => {
  const baseUrl = config.embedURL;
  const playerType = config.embedType;
  const mediaId = config.id ? config.id : config.slug;
  return baseUrl + playerType + mediaId + `/`;
};

interface DepWindow {
  location: {
    origin: string,
    pathname: string,
    search?: string,
  },
}

 interface BuildParamsObject {
  config: Config;
  pbsUid?: string | undefined;
  depWindow?: DepWindow;
}

type ParamsObject = {
  uid?: string;
  userPassportStatus?: string;
  station_id?: string;
  parentURL?: string;
  callsign?: string;
  unsafeDisableContinuousPlay?: boolean;
  unsafePostMessages?: string;
  unsafeDisableUpsellHref?: string;
  start?: string;
  end?: string;
  autoplay?: boolean;
  in_continuous_play_flow?: string;
}

/**
 * Creates parameters to pass to the player
 * @param {BuildParamsObject}
 * @returns {ParamsObject}
 */
const buildParams = ({ config, pbsUid = null, depWindow = null }:BuildParamsObject): ParamsObject => {
  const params: ParamsObject = {};
  const svpCallsign = config.stationVideoPortalCallsign;
  const stationId = config.stationId;
  const isPassportMember = Cookies.get('pbs_mvod') ? true : false;

  if (pbsUid) {
    params['uid'] = pbsUid;
    if (isPassportMember) {
      params['userPassportStatus'] = 'yes';
    } else {
      params['userPassportStatus'] = 'no';
    }
  } else if (depWindow) {
    params['parentURL'] =
      depWindow.location.origin + depWindow.location.pathname;
    params['userPassportStatus'] = 'loggedout';
  } else {
    // if anonymous, set parentURL to the current page
    params['parentURL'] = window.location.origin + window.location.pathname;
    params['userPassportStatus'] = 'loggedout';
  }

  if (svpCallsign) {
    params['callsign'] = svpCallsign;
  }

  if (stationId) {
    params['station_id'] = stationId;
  }

  // this flag will disable continuous play
  // note: player query parameters are prefixed by `unsafe` because
  // the do represent a brittle agreement between the player
  // and it's user
  if (config.disableContinuousPlay) {
    params['unsafeDisableContinuousPlay'] = true;
  }

  // this flag explicitly sets autoplay to true, regardless of continuous play
  if (config.autoplay) {
    params['autoplay'] = true;
  }

  // this flag allows player to receive post messages from pbsorg
  params['unsafePostMessages'] = 'true';

  // tells the player to render a button to trigger sign in
  params['unsafeDisableUpsellHref'] = 'true';

  // to accept query params from the parent page
  // indicating start and end times for the video
  const pageParams = depWindow
    ? depWindow.location.search
    : window.location.search;
  const parsedPageParams = queryString.parse(pageParams);
  // we check for a string in case they mistakenly add more than
  // one 'start' or 'end', which would generate an array and not work
  if (parsedPageParams.start && typeof parsedPageParams.start === 'string') {
    params['start'] = parsedPageParams.start;
  }

  if (parsedPageParams.end && typeof parsedPageParams.end === 'string') {
    params['end'] = parsedPageParams.end;
  }

  // if we are being prompted to this page by an continuous play
  // command, load the video with autoplay enabled
  if (
    parsedPageParams.continuousplayautoplay &&
    parsedPageParams.continuousplayautoplay === 'true'
  ) {
    params['in_continuous_play_flow'] = 'true';
    params['autoplay'] = true;
  }

  return params;
};

/**
 * Creates the query string that is actually passed to the player
 * @param {object} params
 * @returns {string}
 */
const buildQueryString = (params: ParamsObject): string => {
  let queryParamsString = '';

  if (Object.keys(params).length > 0) {
    const queryParams = queryString.stringify(params);
    queryParamsString = `?${queryParams}`;
  }

  return queryParamsString;
};

/**
 * Sets the iframe source
 * @param {Config} - the configuration object, usually passed from window
 * @returns {string}
 */
const buildPlayerUrl = (config: Config): string => {
  const playerUrl = buildPlayerBaseUrl(config);
  const pbsUid = Cookies.get('pbs_uid');
  const params = buildParams({ config, pbsUid });

  return playerUrl + buildQueryString(params);
};

/**
 * Sets the iframe source
 * @param {Config} - the configuration object, usually passed from window
 */
const setIframeSrc = (config: Config) => {
  // passing config to buildPlayerUrl
  const playerUrl = buildPlayerUrl(config);

  // setting the src
  cache.iframe.setAttribute('src', playerUrl);
};

/**
 * Init the iframe
 * @param {Config} - the configuration object, usually passed from window
 */
const pbsPlayerIframeInit = (config?: Config): void => {
  setupCache();
  setIframeSrc(config);
};

/**
 * Play/pause the video on spacebar press, unless the user is
 * tabbing over a button or entering a search query.
 */
const toggleVideoPlayback = (e:KeyboardEvent | React.KeyboardEvent<HTMLElement>, iframe:string): void => {
  const userPressedSpacebar = e.key === " ";

  // grab the element currently in focus
  const focusEl = document.querySelector(':focus');
  // if an element is actually in focus (which won't be the case on a fresh page load)
  // use it's tagName, else use an empty string
  const focusTag = focusEl ? focusEl.tagName : ``;
  // make sure the focused element is not something that should respond to a spacebar normally
  const focusIsNotInputOrButton = focusTag !== `BUTTON` && focusTag !== `INPUT` && focusTag !== `TEXTAREA`;

  if (userPressedSpacebar && focusIsNotInputOrButton) {
    const player = window.frames[iframe];
    const message = JSON.stringify({
      command: 'toggle',
    });

    if (player && typeof player.postMessage === `function`) {
      player.postMessage(message, '*');
    }
    // prevent page scrolling
    e.preventDefault();
  }
};

export {
  pbsPlayerIframeInit,
  buildPlayerBaseUrl,
  buildParams,
  buildQueryString,
  buildPlayerUrl,
  toggleVideoPlayback,
};
