interface FetchConfig {
  method: string;
  headers: Headers;
  body: BodyInit;
}

interface Config {
  urls: {
    [key: string]: {
      [key: string]: string;
    };
  },
  request: {
    method: string;
  }
}

/**
 * Config for default profile values.
 * @type {object} config
 */
const config: Config = {
  urls: {
    favoriteVideo: {
      add: '/profile/addFavoriteVideo/',
      remove: '/profile/removeFavoriteVideo/',
      reorder: '/profile/reorderFavoriteVideo/'
    },
    favoriteShow: {
      add: '/profile/addFavoriteShow/',
      remove: '/profile/removeFavoriteShow/'
    },
    watchedVideo: {
      remove: '/profile/removeWatchedVideo/'
    },
    favoriteStation: {
      add: '/profile/addFavoriteStation/'
    }
  },
  request: {
    method: 'post'
  }
};

/**
 * Sends fetch request for adding or removing something from profile.
 * @param {string} key - property on cache.urls object
 * @param {string} action - add or remove
 * @param {number|string} id - id of specific show/video/station, etc
 * @param {object} options - any additional params to send with call (optional)
 * @returns {Promise}
 */
const sendRequest = (
  key: string,
  action: string,
  id: number | string,
  options?: { async?: boolean; data: {[key: string]: string;} },
): Promise<Response> => {
  const fetchConfig: FetchConfig = {
    method: config.request.method,
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
      'X-Requested-With': 'XMLHttpRequest'
    }),
    body: {} as BodyInit,
  };

  let url: string = config.urls[key] ? config.urls[key][action] : '';

  // add id to url
  if (id) {
    url += id + '/';
  }

  // check to see if additional config options passed in
  if (options) {
    fetchConfig.body = Object.keys(options.data)
      .map(
        (key) =>
          encodeURIComponent(key) + '=' + encodeURIComponent(options.data[key])
      )
      .join('&');
  }

  return fetch(url, fetchConfig)
    .then((response) => response.json())
    .catch(() => {
      // catching errors here for node environments
      // so that tests pass without warnings
    })
};

/**
 * Adds (favorite video or show) to a profile API.
 * @param {string} key - key/type of action to take (matches a property config.urls)
 * @param {object} data - data object for custom values
 * @param {string} data.id - cid of video or show for url
 * @returns {Promise}
 */
const add = (key: string, data: { id: string }): Promise<Response> => {
  const { id } = data;

  return sendRequest(key, 'add', id);
};

/**
 * Formats favorite station data before sending request.
 * @param {string} callsign - station callsign
 * @param {string} zipcode
 */
const formatFavoriteStationData = (callsign: string, zipcode: string) => {
  sendRequest('favoriteStation', 'add', callsign, {
    async: false,
    data: {
      zipcode
    }
  });
};

/**
 * Sets PBS.Profile.favoriteStations.add() available on global scope.
 * @todo find a way to remove from global scope but still be
 * ingested by other repos
 * @param {string} callsign - station callsign
 * @param {string} zipcode
 */
const setupFavoriteStationAPI = (): void => {
  if (window.PBS) {
    window.PBS.Profile = {
      favoriteStations: {
        add: formatFavoriteStationData.bind(this)
      }
    };
  }
};

/**
 * Removes (favorite video or show or watched video) to a profile API.
 * @param {string} key - key/type of action to take (matches a property config.urls)
 * @param {object} data - data object for custom values
 * @param {string} data.id - cid of video or show for url
 * @returns {Promise}
 */
const remove = (key: string, data: { id: string }): Promise<Response> => {
  const { id } = data;

  return sendRequest(key, 'remove', id);
};

/**
 * Reorders a favorite video in the profile API.
 * @param {string} key - key/type of action to take (matches a property config.urls)
 * @param {object} data - data object for custom values
 * @param {string} data.cid - cid of video
 * @returns {Promise}
 */
const reorder = (key: string, data: {cid: string; new_position: string}): Promise<Response> => {
  const { cid, new_position } = data;

  return sendRequest(key, 'reorder', cid, {
    data: {
      new_position
    }
  });
};

export { add, remove, reorder, setupFavoriteStationAPI };
