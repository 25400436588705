import * as Navigation from './navigation';
import * as SignIn from './sign-in';
import * as PassportModal from '../../passport-modal/passport-modal';
import * as Localization from 'scripts/modules/localization';
import * as Profile from 'scripts/modules/profile';
import * as User from './user-onload';
import * as AppearanceToggle from './appearance-toggle';

const init = () => {
  User.init();

  // init the main navigation module
  Localization.init();
  Navigation.init();
  SignIn.init();
  Profile.setupFavoriteStationAPI();
  PassportModal.init();
  AppearanceToggle.init();
  document.removeEventListener('DOMContentLoaded', init);
}

document.addEventListener('DOMContentLoaded', init);
