import { isViewportOver } from 'scripts/utils/isViewportOver';
import { isTouchDevice } from 'scripts/utils/isTouchDevice';
import { hideStationMenu } from './hideStationMenu';

interface CacheExpectations {
  html?: HTMLHtmlElement;
  body?: HTMLBodyElement;
  userTrigger?: HTMLButtonElement;
  nextLinks?: NodeListOf<HTMLLinkElement>;
}

const cache: CacheExpectations = {};

// used in a lot of places, is crucial for styling
const desktopMenuClass = `desktop-user-menu-is-open`;

/**
 * Caches re-used elements.
 */
const setupCache = (): void => {
  cache.body = document.querySelector('body');
  cache.userTrigger = document.querySelector('.user-name-toggle');
  // the first link item under either home or shows
  cache.nextLinks = document.querySelectorAll('.nav-item--home > a, .nav-item--shows > a');
};

/**
 * Show desktop user dropdown menu, and hide the station menu (if it's open)
 */
const showDesktopUserMenu = (): void => {
  hideStationMenu();
  cache.body.classList.add(desktopMenuClass);
  cache.userTrigger.setAttribute('aria-expanded', 'true');
};

/**
 * Show or hide user dropdown menu in side menu based on local storage.
 * @param {String} toggleDirection - direction to toggle {show|hide}
 */
const hideDesktopUserMenu = (): void => {
  cache.body.classList.remove(desktopMenuClass);
  cache.userTrigger.setAttribute('aria-expanded', 'false');
};

/**
 * Handles a click to the user trigger. Some scenarios here are covered
 * in navigation.js > _closeMenusOrShowUserMenu
 */
const onUserTriggerClick = (): void => {
  // This addresses keyboard navigation scenarios at desktop
  if (isViewportOver(1024)) {
    if (cache.body.classList.contains(desktopMenuClass)) {
      hideDesktopUserMenu();
      // for touch devices, we also need to manually force the button
      // to look normal again once the station menu is closed;
      // when we tap it again, it keeps the :hover state, and there's no way
      // to remove the pseudoclass itself--only to give it different hover styles
      if (isTouchDevice()) {
        cache.userTrigger.classList.add('touch-device-disable-hover-styles');
      }
    } else {
      // Let's show the desktop user menu
      showDesktopUserMenu();
      // in case it's a touch device and they've already closed the station menu,
      // we also need to remove the special class to get rid of the :hover styles
      if (isTouchDevice()) {
        cache.userTrigger.classList.remove('touch-device-disable-hover-styles');
      }
    }
  }
};

/**
 * Handles a focus on the user trigger on desktop
 */
const onUserTriggerFocus = (): void => {
  if (
    isViewportOver(1024) &&
    !isTouchDevice() &&
    !cache.body.classList.contains(desktopMenuClass)
  ) {
    showDesktopUserMenu();
  }
};

/**
 * Adds event handlers.
 */
const addEvents = (): void => {
  if (isTouchDevice()) {
    cache.userTrigger.addEventListener('touchend', onUserTriggerClick);
  } else {
    // When the user clicks on the user trigger, both on mobile and desktop breakpoints
    cache.userTrigger.addEventListener('click', onUserTriggerClick);
    // When the user focuses on the user trigger, both on mobile and desktop breakpoints
    cache.userTrigger.addEventListener('focus', onUserTriggerFocus);
  }

  cache.nextLinks.forEach((link) => link.addEventListener('focus', hideDesktopUserMenu));
};

/**
 * On init.
 */
const init = (): void => {
  setupCache();
  addEvents();
};

export { init, hideDesktopUserMenu, showDesktopUserMenu };
