import { WindowPBS } from 'scripts/modules/window.PBS';

type PersonalData = WindowPBS["PBS"]["personalData"] | null;

/**
 * Before the call to /personal/ is made in user-onload.js,
 * the window.PBS.personalData object is undefined. This async
 * function waits for it to either have data (the user is signed
 * in) or be set to null (the user is not signed in).
 */
const getPersonalData = async (): Promise<PersonalData> => {
  while (
    typeof window.PBS === 'undefined' ||
    typeof window.PBS.personalData === 'undefined'
  ) {
    // wait half a second, then try again
    await new Promise((r) => setTimeout(r, 500));
    return getPersonalData();
  }
  return window.PBS.personalData;
};

export { getPersonalData };
