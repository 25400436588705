import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';

import { init as videoSummariesInit } from 'components/video/summary/video';
import { arrowPath } from 'components/carousel-arrows/arrow-path';
import { hideShowNextArrow } from 'components/carousel-arrows/hide-show-next-arrow';

interface VideoRowCache {
  videoRows?: NodeListOf<HTMLElement>;
  [key: string]: Splide | NodeListOf<HTMLElement>;
}

const cache: VideoRowCache = {};

/**
 * Caches re-used elements
 */
const setupCache = () => {
  cache.videoRows = document.querySelectorAll('.video-row__container');
};

/**
 * Sets up a Video Row.
 */
 const setupVideoRows = () => {
  if (cache.videoRows?.length > 0) {
    cache.videoRows.forEach((videoRow: HTMLElement, index: number) => {
      // init the video summaries (apply tooltips and popovers)
      videoSummariesInit(videoRow);
      // legacy style: no content bleed at edges; 3-up max rather than 4
      const useLegacyStyle = videoRow.parentElement.classList.contains(`video-row--legacy-style`);
      const maxPerPage = useLegacyStyle ? 3 : 4;
      cache[`videoRow${index}`] = new Splide(videoRow, {
        arrowPath: arrowPath,
        breakpoints: {
          1280: {
            perPage: 3,
          },
          768: {
            gap: '14px',
            perPage: 2,
          },
          480: {
            perPage: 1,
          },
        },
        classes: {
          arrow: 'carousel__arrow',
          prev: 'splide__arrow--prev carousel-prev',
          next: `splide__arrow--next carousel-next is-hidden`,
        },
        gap: '16px',
        perPage: maxPerPage,
        pagination: false,
        throttle: 300,
      });

      const newCarousel = cache[`videoRow${index}`] as Splide;
      hideShowNextArrow(newCarousel, videoRow);
      newCarousel.mount();
      setupSeeAllButton(index);
    });
  }
}

const setupSeeAllButton = (index: number) => {
  const carouselArrows = cache.videoRows[index].querySelector('.splide__arrows');
  const seeAllDiv = cache.videoRows[index].querySelector('.carousel__see-all');

  if (carouselArrows && seeAllDiv) {
    carouselArrows.appendChild(seeAllDiv);
  }
}

const init = (): void => {
  setupCache();
  setupVideoRows();
}

export { init };
