import * as PassportModalLogic from './passport-modal-child';
import Modal from '../modal/modal';
import Cookies from 'js-cookie';

import { customEvents } from 'scripts/custom-events';

interface CacheExpectations {
  passportModal?: HTMLDivElement;
}

const cache:CacheExpectations = {};

/**
 * Sets up cache
 */
const setupCache = () => {
  cache.passportModal = document.querySelector('#modalPassport');
};

interface ModalInterface {
  show: (arg0?: Record<string, unknown>) => void;
}

// ModalInterface found in global-types.d.ts
let passportModal: ModalInterface;

/**
 * Creates modal
 */
const setupPassportModal = () => {
  const options = {
    modalId: '#modalPassport',
    focusTarget: '#passport-modal__dialog',
    childView: PassportModalLogic,
  };

  passportModal = new Modal(options);
};

/**
 * Shows modal
 */
const showModal = () => {
  passportModal.show();
};

/**
 * Determines whether or not we should show the modal
 * At this point, we know that:
 * 1. The localized station is Passport
 * 2. The user came from the login flow
 * 3. The user is not a Passport Member
 * All that is left to do is to see if they've seen the modal already
 */
const doWeAnnoyUserWithModal = () => {
  const isModalHidden = Cookies.get('pbs.hidePassportModal') ? true : false;

  if (!isModalHidden) {
    showModal();
    window.removeEventListener(
      customEvents.userIsNotPassportAndIsSigningIn,
      doWeAnnoyUserWithModal
    );
  }
};

/**
 * Add Events
 */
const addEvents = () => {
  // The userIsNotPassportAndIsSigningIn event is emitted when
  // the user's data is loaded, they are not passsport, and are signing in
  // See user-onload.js for the orginating event
  window.addEventListener(
    customEvents.userIsNotPassportAndIsSigningIn,
    doWeAnnoyUserWithModal
  );
};

/**
 * Initializes
 */
const init = (): void => {
  setupCache();

  // Let's make sure there is a modal to set up (which won't be the case in non-Passport stations)
  if (cache.passportModal) {
    addEvents();
    setupPassportModal();
  }
};

export { init };
