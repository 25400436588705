import React from 'react';

import PosterWithMenu from 'components/continue-watching/poster-with-menu/poster-with-menu';
import { Video } from 'components/shared/content-services-types';

interface ContinueWatchingUserMenuRowProps {
  posterData: Video[];
  getVideoSummary?: (video: Video) => string;
}

const ContinueWatchingUserMenuRow: React.FC<ContinueWatchingUserMenuRowProps> = (props) => {
  const { posterData, getVideoSummary } = props;

  const posters = posterData.map((item) => 
    <PosterWithMenu
      key={item.cid}
      posterType="continue-watching"
      show={item.show}
      percentComplete={(item.seconds_watched / item.duration) * 100}
      videoSlug={item.slug}
      videoSummary={getVideoSummary(item)}
      videoCid={item.cid}
      userMenuLayout={true}
    />
  );

  return (
    <div className="continue-watching-user-menu-row" data-testid="rtl-userMenuRow">
      {posters}
    </div>
  );
}

export default ContinueWatchingUserMenuRow;