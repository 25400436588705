import Cookies from 'js-cookie';
import { isLocalStorageSupported, getItem } from './storage';

/**
 * Checks if user just came from signing in, by checking
 * the localStorage flag as well as the uid cookie
 * @returns {string|boolean} - cookie string or false if not signed in
 */
const isComingFromSigningIn = (): string | boolean => {
  if (!isLocalStorageSupported()) {
    return false;
  } else {
    return Cookies.get('pbs_uid') && getItem('tryingToLogin');
  }
};

export { isComingFromSigningIn };
