/**
 * Checks if screen size is over a given size. Defaults to the medium (1024) breakpoint.
 * @param {string} sizeParam - size for min width conditional
 * @returns {boolean}
 */
const isViewportOver = (sizeParam?: number): boolean => {
  const size = sizeParam || '1024';

  if (window.matchMedia('(min-width: ' + size + 'px)').matches) {
    return true;
  }

  return false;
};

export { isViewportOver };
