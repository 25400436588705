import Cookies from 'js-cookie';
import { storageAvailable } from 'scripts/utils/storage';
import { customLogMsg } from './customLogMsg';

const getUidDate = (): string | null => {
  return Cookies.get('pbs_uid_date') || null;
};

const getUidDateDiffFromNowInDays = (uidDate: string): number => {
  const date_uid_date = Date.parse(uidDate);
  const now = Date.now();
  const now_date_uid_date_diff = now - date_uid_date;
  const now_date_uid_date_diff_days =
    now_date_uid_date_diff / (1000 * 60 * 60 * 24);
  // Even if it's been 5.99 days, that's still 5 days, not 6
  return Math.floor(now_date_uid_date_diff_days);
};

const LOGGED_USER_LOGIN_LENGTH = 'LOGGED_USER_LOGIN_LENGTH';

/**
 * Logs the difference in days between the pbs_uid_date cookie and the current date
 * @param {string} trigger - What is triggering this log
 */
const logUidDate = (
  trigger: 'loginSuccess' | 'gettingDataFailure' | 'userIsNotLoggedIn'
): void => {
  // Check for pbs_uid_date cookie
  const uidDate = getUidDate();

  // Return if:
  //   If no pbs_uid_date cookie
  //   Or if the user doesn't have sessionStorage
  //   If we've already logged the user login length
  if (
    uidDate === null ||
    !storageAvailable('sessionStorage') ||
    sessionStorage.getItem(LOGGED_USER_LOGIN_LENGTH) === 'true'
  ) {
    return;
  }

  // Get diff of now from pbs_uid_date in round days
  const nowDiff = getUidDateDiffFromNowInDays(uidDate);

  // Compose triggerMsg based on trigger
  let triggerMsg = '';

  switch (trigger) {
    case 'loginSuccess':
      triggerMsg = 'User is logged in';
      break;
    case 'gettingDataFailure':
      triggerMsg = 'Failed to get user data';
      break;
    case 'userIsNotLoggedIn':
      triggerMsg = 'User is not logged in but the uid date cookie is present';
      break;
  }

  // Use customLogMsg to log the diff
  const logMsg = JSON.stringify({
    msg: triggerMsg,
    userLoginLengthInDays: nowDiff,
  });

  customLogMsg(logMsg);

  // Set a boolean flag in session storage to prevent logging again
  sessionStorage.setItem(LOGGED_USER_LOGIN_LENGTH, 'true');
};

export { logUidDate };
