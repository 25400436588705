interface CacheExpectations {
  facebookAndTwitterLinks?: NodeListOf<HTMLLinkElement>;
  externalLinks?: NodeListOf<HTMLLinkElement>;
}

const cache: CacheExpectations = {};

/**
 * Caches re-used elements.
 */
const setupCache = (): void  => {
  cache.facebookAndTwitterLinks = document.querySelectorAll('a.facebook-share, a.twitter-share');
  cache.externalLinks = document.querySelectorAll('a[target="_blank"]');
};

/**
 * Handles clicks on social share links
 */
const onFacebookAndTwitterLinkClick = (e: Event): void => {
  const target:HTMLLinkElement = e.currentTarget as HTMLLinkElement;
  const url = target.href;
  // we need differntly sized windows depending on
  // whether it's Facebook or Twitter
  const height = target.classList.contains('facebook-share') ? 360 : 420;
  const windowFeatures = `status=1, height=${height}, width=500, resizable=0`;

  e.preventDefault();

  // if there is a URL
  if (url) {
    // open this link in a new window;
    window.open(
      url,
      '_blank',
      windowFeatures
    );
  }
};

/**
 * Adds external link indicator for screen reader users
 */
const addExternalLinkIndicator = (): void => {
  cache.externalLinks.forEach((externalLink) => {
    const ariaText = externalLink.innerText;
    externalLink.setAttribute("aria-label", `${ariaText} (opens in new window)`);
  });
};

/**
 * Adds event handlers.
 */
const addEvents = (): void => {
  cache.facebookAndTwitterLinks.forEach((link) => {
    link.addEventListener('click', onFacebookAndTwitterLinkClick);
  })
};

/**
 * Initializes.
 */
const init = () => {
  setupCache();
  addEvents();
  addExternalLinkIndicator();
}

init();

export {};