export const PBS_config = {
  // here are the defined breakpoints;
  breakpoints: {
    // if they change in the Sass, they should change here too;
    sm: 768,
    md: 1024,
    lg: 1280,
    // Splide.js Carousel uses these breakpoints when determining how many slides to show;
    // but they need to be 1px less;
    splide: {
      smaller: 570, // not used in CSS, only in carousel breakpoints;
      sm: 767,
      md: 1023,
      lg: 1279,
    },
  },
};
