import { proceedToAddItem } from "../my-list-button/utils/proceedToAddItem";
import { MaxedOutCache, MaxedOutModalData } from "../my-list-button/types/my-list-button";

const cache: MaxedOutCache = {};

const setupCache = () => {
  cache.modal = document.getElementById('maxedOutListModalWindow');
  cache.videosMessage = document.getElementById('maxedOutVideos');
  cache.showsMessage = document.getElementById('maxedOutShows');
  cache.videosButton = document.getElementById('removeFirstVideo');
  cache.showsButton = document.getElementById('removeFirstShow');
  cache.body = document.body;
};

const addEvents = (data: MaxedOutModalData) => {
  cache.videosButton.addEventListener('click', () => proceedToAddItem(data));
  cache.showsButton.addEventListener('click', () => proceedToAddItem(data));
}

const updateMessaging = (contentType: 'video' | 'show') => {
  if (contentType === 'video') {
    cache.videosMessage.classList.remove('is-hidden');
    cache.showsMessage.classList.add('is-hidden');
  } else if (contentType === 'show') {
    cache.showsMessage.classList.remove('is-hidden');
    cache.videosMessage.classList.add('is-hidden');
  }
};

const init = (): void => {
  setupCache();
};

const onShow = (data: MaxedOutModalData): void => {
  // hide any nav if it is open
  cache.body.classList.remove('nav-is-open');

  // show correct message
  updateMessaging(data.type);
  addEvents(data);
};

export { init, onShow };
