import Cookies from 'js-cookie';

/**
 * Returns the pbs_uid cookie value if it exists, otherwise returns null
 * @returns {string|null}
 */
const getUserId = (): string | null => {
  const uidCookieValue = Cookies.get('pbs_uid');
  return uidCookieValue ? uidCookieValue : null;
};

export { getUserId };
