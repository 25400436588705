/**
 * Gives correct cookie domain based on hostname.
 * On SVP qa sites (e.g. weta-qa.svp.pbs.org) we use the full hostname.
 * On *.pbs.org, we use '.pbs.org', to support legacy behavior.
 * In all other cases (such as station video portals in production), we use the full hostname.
 */
const getCookieDomain = (): string => {
  const hostname = window.location.hostname;

  switch (true) {
    case hostname.includes('.svp.pbs.org'):
      // we have to do this redundantly so that we can catch
      // the SVP qa sites
      return hostname;
      break;
    case hostname.includes('.pbs.org'):
      // *then* pbs.org sites
      return '.pbs.org';
      break;
    default:
      // then everything else (e.g. SVP Prod sites)
      return hostname;
  }
};

export { getCookieDomain };
