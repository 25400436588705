import React from 'react';
import { createRoot } from 'react-dom/client';

import MyListButton from './button';
import { Cache } from '../types/my-list-button';

/**
 * Finds the places in HTML markup where a My List button should render,
 * and renders them. For the individual My List button component, see
 * my-list/my-list-button/components/button.tsx
 */

const cache: Cache = {};

const setupCache = () => {
  cache.MyListButtonPlaceholders = document.querySelectorAll(
    '.btn--mylist--placeholder'
  );
};

const renderMyListButtons = (placeholders = cache.MyListButtonPlaceholders): void => {
  placeholders.forEach((MyListButtonPlaceholder) => {
    const data = MyListButtonPlaceholder.dataset;
    const gtmLabel = data.gtmLabel;
    const cid = data.cid;
    const contentType = data.contentType;
    const slug = data.slug;
    const root = createRoot(MyListButtonPlaceholder!);
    root.render(
      <MyListButton
        gtmLabel={gtmLabel}
        cid={cid}
        contentType={contentType}
        slug={slug}
      />
    );
  });
};

const init = (): void => {
  setupCache();
  renderMyListButtons();
};

export { init };
