import React, { useState, useEffect } from 'react';

interface ImageWithFallbackProps {
  path: string;
  isBroken: boolean;
  title: string;
  customClass?: string;
  crop?: string;
  crop2x?: string;
  onBrokenImage: (arg0: boolean) => void;
  textOverlay?: boolean;
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = (props) => {
  const {
    path,
    isBroken,
    title,
    customClass,
    crop,
    crop2x,
    onBrokenImage,
    textOverlay,
  } = props;

  const [fallbackImage, setFallbackImage] = useState(false);

  const setFallbackState = () => {
    setFallbackImage(true);
    onBrokenImage(true);
  }

  useEffect(() => {
    // if no path given, setup fallback to prevent 404
    if (!path || isBroken) {
      setFallbackState();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Callback for when image errors and need to implement fallback image.
   * @param {React event} e
   */
  const handleImageError = () => {
    if (!fallbackImage) {
      setFallbackState();
    }
  }

  /**
   * Gets image elements to render.
   */
  const getImageView = () => {
    if (!fallbackImage) {
      const srcPath = crop ? path + crop : path;
      const srcsetPath = crop2x
        ? path +
          crop +
          ' 1x, ' +
          path +
          crop2x +
          ' 2x'
        : null;

      return (
        <img
          className={customClass + '-image image--needs-fallback'}
          src={srcPath}
          srcSet={srcsetPath}
          alt={title}
          onError={handleImageError}
          data-testid="rtl-cy-image-with-fallback"
        />
      );
    } else {
      return (
        <div className="fallback-image__text-container">
          {textOverlay ? (
            <span className="fallback-image__text">{title}</span>
          ) : null}
        </div>
      );
    }
  }

  const view = getImageView();

  return (
    <div className={customClass}>{view}</div>
  );
}

export default ImageWithFallback;
