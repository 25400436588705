import { init as scheduleInit } from 'components/schedule/schedule';
import { init as timezoneToggleInit } from 'components/timezone-toggle/timezone-toggle';

interface CacheExpectations {
  pageHeaderTVScheduleScheduleToday?: HTMLDivElement;
  pageHeaderTVScheduleScheduleTomorrow?: HTMLDivElement;
  pageHeaderTVScheduleTimezoneToggle?: HTMLDivElement;
}

const cache: CacheExpectations = {};

/**
 * Caches re-used elements.
 */
const setupCache = () => {
  cache.pageHeaderTVScheduleScheduleToday = document.querySelector(
    '.tv-schedules-dropdown__schedule--today .schedule'
  );
  cache.pageHeaderTVScheduleScheduleTomorrow = document.querySelector(
    '.tv-schedules-dropdown__schedule--tomorrow .schedule'
  );
  cache.pageHeaderTVScheduleTimezoneToggle = document.querySelector(
    '.tv-schedules-dropdown__footer .timezone-toggle'
  );
};

/**
 * On init.
 */
const init = (): void => {
  setupCache();
  scheduleInit(cache.pageHeaderTVScheduleScheduleToday);
  scheduleInit(cache.pageHeaderTVScheduleScheduleTomorrow);
  timezoneToggleInit(cache.pageHeaderTVScheduleTimezoneToggle);
};

export { init };
