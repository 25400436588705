import ResponsiveAd from './ad-responsive';
import _debounce from 'lodash.debounce';

const LazyloadAd = class LazyloadAd extends ResponsiveAd {
  lazyloadHeight: number;
  lazyloadBuffer: number;
  el: HTMLElement;

  /**
   * Initializes class instance.
   * param {object} options - any custom options passed in
   * @overrides
   */
  constructor(options: Defaults) {
    super(options);

    const defaults: Defaults = {
      lazyload: false,
      lazyloadBuffer: 300,
      lazyloadHeight: 0,
    };

    // loop through all defaults and set value to any options passed in
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.updateDefaults(this, defaults, options);

    this.onWindowScroll = _debounce(this.onWindowScroll.bind(this), 200);
  }

  /**
   * Sets lazyloading height to track scroll against.
   */
  setLazyloadHeight = (): void => {
    if (this.lazyloadHeight === 0 && this.el) {
      const fromTop = this.el.getBoundingClientRect().top;
      this.lazyloadHeight = fromTop - this.lazyloadBuffer;
    }
  };

  /**
   * Lazyloads ads.
   */
  lazyloadAd = (): void => {
    // set up heights if not already set
    this.setLazyloadHeight();

    const toShowFromBottom = window.innerHeight - this.lazyloadBuffer;
    const currentPosFromTop = window.pageYOffset;
    const adPos = this.lazyloadHeight - currentPosFromTop;

    // if we are scrolled to a point where we should show the ad
    if (toShowFromBottom >= adPos) {
      // if slot has been registered, load it
      if (this.slot) {
        this.refreshSlot();
      }
    }
  };

  /**
   * Loads ad via lazyloading
   * @override - overrides _load in ResponsiveAd class
   */
  load = (): void => {
    // if already loaded, do nothing
    if (this.loaded) {
      return;
    }

    // attempt to load
    this.lazyloadAd();
  };

  /**
   * On window scroll event.
   */
  onWindowScroll = (): void => {
    this.load();

    if (this.loaded) {
      window.removeEventListener('scroll', this.onWindowScroll);
    }
  };

  /**
   * Adds event handlers.
   * @override - overrides _addEvents in ResponsiveAd class
   */
  addEvents(): void {
    super.addEvents();

    // add window scroll event for lazy-loading ads
    window.addEventListener('scroll', this.onWindowScroll);
  }
};

export default LazyloadAd;
