/**
 * Hides the right side station menu.
 */
const hideStationMenu = (): void => {
  document.querySelector('body').classList.remove('station-is-open');
  if (document.querySelector('.page-header__station-trigger')) {
    document.querySelector('.page-header__station-trigger').setAttribute('aria-expanded', 'false');
  }
};

export { hideStationMenu }
