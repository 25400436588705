import { customEvents } from 'scripts/custom-events';

interface CacheExpectations {
  featuredShowPosters?: NodeListOf<HTMLLabelElement>;
  watchNowButtons?: NodeListOf<HTMLElement>;
  becomeAMemberButtons?: NodeListOf<HTMLElement>;
  learnMoreButtons?: NodeListOf<HTMLElement>;
}


const cache: CacheExpectations = {};

/**
 * Caches re-used elements.
 * @returns {function} setupCache
 */
const setupCache = () => {
  cache.featuredShowPosters = document.querySelectorAll(
    '.passport-learn-more__featured-shows__poster'
  );
  cache.watchNowButtons = document.querySelectorAll('.passport-learn-more__featured-shows__watch-now');
  cache.becomeAMemberButtons = document.querySelectorAll('.passport-learn-more__featured-shows__become-a-member');
  cache.learnMoreButtons = document.querySelectorAll('.passport-learn-more__featured-shows__learn-more');
};

/**
 * Handles differing component behavior once a user is
 * identified as a Passport member.
 */
 const onUserIsPassport = () => {
  // if a Passport member is already signed in, we update the
  // component to un-hide the Passport member specific elements
  cache.watchNowButtons.forEach((button) => button.classList.remove('is-hidden'));

  window.removeEventListener(customEvents.userIsPassport, onUserIsPassport);
}

/**
 * Handles differing component behavior once a user is
 * identified as not a Passport member or not signed in.
 */
const onUserIsDefault = () => {
  // un-hide all the page elements that ask the user to become a member
  cache.becomeAMemberButtons.forEach((button) => button.classList.remove('is-hidden'));
  cache.learnMoreButtons.forEach((button) => button.classList.remove('is-hidden'));
}

/**
 * Preloads the background image associated to a show poster when the poster is hovered.
 */
const hoverTargetPreload = (e:Event) => {
  const hoverTargetClass = `.${(<HTMLLabelElement>e.currentTarget).getAttribute('data-hover-target')}`;
  const hoverTarget = document.querySelector(hoverTargetClass) as HTMLImageElement;
  if (hoverTarget.loading === 'lazy') {
    hoverTarget.loading = 'eager';
  }
  e.target.removeEventListener('mouseover', hoverTargetPreload);
}

const addEvents = () => {
  window.addEventListener(customEvents.userIsPassport, onUserIsPassport);
  window.addEventListener(customEvents.userIsNotLoggedIn, onUserIsDefault);
  window.addEventListener(customEvents.userIsNotPassport, onUserIsDefault);
  cache.featuredShowPosters.forEach((poster) => {
    poster.addEventListener('mouseover', hoverTargetPreload);
    poster.addEventListener('click', hoverTargetPreload);
  });
}

const init = (): void => {
  setupCache();
  addEvents();
}

export { init };
