import { isViewportOver } from 'scripts/utils/isViewportOver';
import { isTouchDevice } from 'scripts/utils/isTouchDevice';
import { isTabKeyPress } from 'scripts/utils/isTabKeyPress';
import { init as scheduleInit } from 'components/schedule/schedule';
import { init as timezoneToggleInit } from 'components/timezone-toggle/timezone-toggle';
import { hideNav } from './navigation-side';
import { hideStationMenu } from './hideStationMenu';

interface CacheExpectations {
  body?: HTMLBodyElement;
  stationTrigger?: HTMLButtonElement;
  stationContainer?: HTMLDivElement;
  stationLinks?: NodeListOf<HTMLAnchorElement>;
  changeStationButton?: HTMLButtonElement;
  pageHeaderStationScheduleSchedule?: HTMLElement;
  pageHeaderStationLinks?: NodeListOf<HTMLAnchorElement>;
  pageHeaderStationLastLink?: HTMLAnchorElement;
  pageHeaderStationScheduleTimezoneToggle?: HTMLElement;
}

const cache: CacheExpectations = {};

/**
 * Caches re-used elements.
 */
const setupCache = (): void => {
  cache.body = document.querySelector('body');
  cache.stationTrigger = document.querySelector('.page-header__station-trigger');
  cache.stationContainer = document.querySelector('.page-header__station-container');
  cache.stationLinks = document.querySelectorAll('.station-links__local-regular');
  cache.changeStationButton = document.querySelector('.station-info__change-station');
  cache.pageHeaderStationScheduleSchedule = document.querySelector(
    '.page-header-station-schedule .schedule'
  );

  if (cache.stationContainer) {
    cache.pageHeaderStationLinks = cache.stationContainer.querySelectorAll('a');
    cache.pageHeaderStationLastLink = [...cache.pageHeaderStationLinks].pop();
  }

  cache.pageHeaderStationScheduleTimezoneToggle = document.querySelector(
    '.page-header-station-schedule .timezone-toggle'
  );
};

/**
 * Shows the right side station menu.
 */
const showStationMenu = (): void => {
  // make sure the nav is closed
  cache.body.classList.remove('nav-is-open');
  // make sure the user dropdown is closed
  cache.body.classList.remove('desktop-user-menu-is-open');
  // show the station menu
  cache.body.classList.add('station-is-open');

  if (cache.stationTrigger) {
    cache.stationTrigger.setAttribute('aria-expanded', 'true');
  }
};

/**
 * Handles a click to the station trigger (right side station menu button).
 */
const onStationTriggerClick = (): void => {
  hideNav();
  if (cache.body.classList.contains('station-is-open')) {
    hideStationMenu();
    // for touch devices, we also need to manually force the button
    // to look normal again once the station menu is closed;
    // when we tap it again, it keeps the :hover state, and there's no way
    // to remove the pseudoclass itself--only to give it different hover styles
    if (isTouchDevice()) {
      cache.stationTrigger.classList.add('touch-device-disable-hover-styles');
    }
  } else {
    showStationMenu();
    // in case it's a touch device and they've already closed the station menu,
    // we also need to remove the special class to get rid of the :hover styles
    if (isTouchDevice()) {
      cache.stationTrigger.classList.remove('touch-device-disable-hover-styles');
    }
  }
};

/**
 * Handles a focus to the station trigger (right side station menu button).
 */
const onStationTriggerFocus = (): void => {
  if (!cache.body.classList.contains('station-is-open') && isViewportOver(1024)) {
    showStationMenu();
  }
};

/**
 * If user clicks on station dropdown at 1024px+ screen,
 * we want the station dropdown to hide on mouseleave
 */
const onStationDropdownLeave = (): void => {
  if (isViewportOver(1024) && !isTouchDevice()) {
    hideStationMenu();
  }
};

/**
 * If user clicks on station dropdown at 1024px+ screen,
 * we want the station dropdown to hide on mouseleave
 */
const onLastElTab = (e:KeyboardEvent): void => {
  // if the keyCode is not tab (9) OR the shift key is being held, return
  if (isTabKeyPress(e) && !isViewportOver(1024) && cache.body.classList.contains('station-is-open')) {
    // send the user back to the mainNavTrigger if
    // the nav is open and we're below 1024
    e.preventDefault();
    cache.stationTrigger.focus();
  }
};

/**
 * Adds event handlers.
 */
const addEvents = (): void => {

  if (cache.stationTrigger) {
    if (isTouchDevice()) {
      cache.stationTrigger.addEventListener('touchend', onStationTriggerClick);
    } else {
      // Open/close right side station menu on click
      cache.stationTrigger.addEventListener('click', onStationTriggerClick);

      // Open/close right side station menu on focus
      cache.stationTrigger.addEventListener('focus', onStationTriggerFocus);
    }
  }

  if (cache.stationContainer) {
    // We want to hide the station menu
    // if a user has triggered the station-is-visible class but have moused out -
    // we need 'mouseleave' so it works on child elements
    cache.stationContainer.addEventListener('mouseleave', onStationDropdownLeave);
  }

  if (cache.stationLinks.length !== 0) {
    const lastStationLink = [...cache.stationLinks].pop();
    lastStationLink.addEventListener('keydown', onLastElTab);
  } else {
    if (cache.changeStationButton) {
      cache.changeStationButton.addEventListener('keydown', onLastElTab);
    }
  }

  // The Schedule Link is the last focusable element - if the user tabs from here
  // we close the station drop down
  cache.pageHeaderStationLastLink?.addEventListener('keydown', (e) => {
    if (isTabKeyPress(e)) {
      onStationDropdownLeave();
    }
  })
};

/**
 * On init.
 */
const init = (): void => {
  setupCache();
  addEvents();

  scheduleInit(cache.pageHeaderStationScheduleSchedule);
  timezoneToggleInit(cache.pageHeaderStationScheduleTimezoneToggle);
};

export { init, hideStationMenu, showStationMenu };
