import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';

import { arrowPath } from 'components/carousel-arrows/arrow-path';
import { hideShowNextArrow } from 'components/carousel-arrows/hide-show-next-arrow';

interface ExploreMoreTopicsCache {
  exploreMoreTopics?: HTMLDivElement;
  [key: string]: Splide | HTMLDivElement;
}

const cache: ExploreMoreTopicsCache = {};

/**
 * Caches re-used elements
 */
const setupCache = () => {
  cache.exploreMoreTopics = document.querySelector(
    '.explore-more-topics__container.splide'
  );
};

/**
 * Sets up each Explore More Topics instance.
 */
const setupExploreMoreTopics = () => {
  if (cache.exploreMoreTopics) {
    cache[`carousel`] = new Splide(cache.exploreMoreTopics, {
      arrowPath: arrowPath,
      breakpoints: {
        1024: {
          padding: {
            left: '64px',
            right: '64px',
          },
          perPage: 4,
        },
        767: {
          gap: '12px',
          padding: {
            left: '32px',
            right: '32px',
          },
          perPage: 3,
        },
        550: {
          gap: '8px',
          padding: {
            left: '32px',
            right: '32px',
          },
          perPage: 2,
        }
      },
      classes: {
        arrow: 'carousel__arrow',
        prev: 'splide__arrow--prev carousel-prev',
        next: `splide__arrow--next carousel-next is-hidden`,
      },
      gap: '16px',
      padding: {
        left: '64px',
        right: '64px',
      },
      pagination: false,
      perPage: 5,
      throttle: 300,
    })

    hideShowNextArrow(cache[`carousel`], cache.exploreMoreTopics);

    cache[`carousel`].mount();
  }
};

/**
 * Initializes component.
 */
const init = (): void => {
  setupCache();
  setupExploreMoreTopics();
};

export { init };
