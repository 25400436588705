import React, { useState, useEffect } from 'react';
import fetch from 'isomorphic-fetch';
import { createRoot } from 'react-dom/client';

import { RelatedAssetModal } from './related-asset-modal';
import { RelatedAssetLinkProps, RelatedAssetProps, RelatedVideoData, RelatedVideoParent, VideoData } from './types';
import { isSessionStorageSupported } from 'scripts/utils/storage';

function RelatedAssetLink(props: RelatedAssetLinkProps) {
  const { videoData, text, gtmLabel, displayAsText } = props;
  const linkURL = `/video/${videoData.slug}/`;
  const classList = displayAsText ? `related-asset--text an-50_2` : `btn btn--fill--blue an-50_2`;
  return (
    <a
      href={linkURL}
      className={classList}
      data-gtm-label={gtmLabel}
    >
      {text}
    </a>
  );
}

const RelatedAsset = (props: RelatedAssetProps) => {
  const { video, displayAsText } = props;
  const [fetching, setFetching] = useState(false);
  const [videoType, setVideoType] = useState(null);
  const [data, setData] = useState(null);
  const [modalIsVisible, setModalIsVisible] = useState(false);

  useEffect(() => {
    // check sessionStorage for parent video slug to avoid making repeat calls
    const relatedAsset = checkSessionStorageForRelatedAsset(video.slug);
    if (relatedAsset) {
      getChildComponent(relatedAsset);
    } else {
      // otherwise, make a new related asset request and cache the result
      setFetching(true);
      const url = `/video/${video.slug}/related/`;
      const options = {
        headers: new Headers({ 'X-Requested-With': 'XMLHttpRequest' })
      };
      fetch(url, options)
        .then((response) => response.json())
        .then((relatedAssetData) => {
          if (isSessionStorageSupported()) {
            cacheRelatedAsset(relatedAssetData);
          }
          getChildComponent(relatedAssetData.related);
        })
        .catch(() => {
          // catching errors here for node environments
          // so that tests pass without warnings
        })
    }
  }, [video]);

  const checkSessionStorageForRelatedAsset = (parentVideoSlug: string) => {
    let relatedAsset = null;
    if (isSessionStorageSupported() && sessionStorage.getItem(parentVideoSlug)) {
      relatedAsset = JSON.parse(sessionStorage.getItem(parentVideoSlug));
    }
    return relatedAsset;
  }

  const cacheRelatedAsset = (relatedAssetData: RelatedVideoParent) => {
    // if we can use sessionStorage, cache the related asset data there
    // so that we can avoid making multiple repeated calls if user
    // mouses over the video more than once
    const parentVideoSlug = relatedAssetData.self;
    const relatedAsset = JSON.stringify(relatedAssetData.related);
    sessionStorage.setItem(parentVideoSlug, relatedAsset);
  }

  const getChildComponent = (relatedAsset: RelatedVideoData) => {
    const related = relatedAsset;
    let videoType = null;

    if (related && related.related_video_type) {
      videoType = related.related_video_type;
    }
    setFetching(false);
    setData(related);
    setVideoType(videoType);
  }

  const getCTAText = (videoType: string) => {
    // fallback in case we get data that we don't expect
    let text = 'Watch Related Video';

    if (videoType == 'preview') {
      text = 'Watch Preview';
    } else if (videoType == 'clip') {
      text = 'Watch Clip';
    } else if (videoType == 'full_length') {
      text = 'Watch Full Length';
    }

    return text;
  }

  const availability = data && data.related_video_availability;
  const isRedundant =
    data && document.location.pathname === `/video/${data.slug}/`;
  const displayText = data && getCTAText(data.related_video_type);
  // We don't want to render anything while the data is fetching,
  // if the related asset endpoint returns a null video,
  // if it returns data for a video that is not currently available,
  // or if the related asset lives on the same page as our current location.
  const shouldNotDisplay =
    fetching ||
    videoType === null ||
    availability !== 'available' ||
    isRedundant;
  const gtmLabel = `${video.ancestor} | ${video.title} |
  ${video.cid} | ${video.type}`;

  const classList = displayAsText ? `related-asset--text an-50_1` : `btn btn--fill--blue an-50_1`;

  return shouldNotDisplay ? null : (
    <>
      {videoType === 'full_length' ? (
        <RelatedAssetLink
          videoData={data}
          text={displayText}
          gtmLabel={gtmLabel}
          displayAsText={displayAsText}
        />
      ) : (
        <>
          <button
            className={classList}
            data-gtm-label={gtmLabel}
            onClick={() => setModalIsVisible(true)}
          >
            {displayText}
          </button>
          {modalIsVisible ? (
            <RelatedAssetModal
              videoData={data}
              handleClose={() => setModalIsVisible(false)}
            />
          ) : null}
        </>
      )}
    </>
  );
}

function initializeRelatedAsset(video: VideoData, placeholderElement: HTMLElement, displayAsText: boolean): void {
  const root = createRoot(placeholderElement!);
  root.render(<RelatedAsset video={video} displayAsText={displayAsText} />);
}

export { initializeRelatedAsset, RelatedAsset };

